@import '../../styleguide/variables';

.message {
  justify-content: center;
  & h3 {
    font-size: 20px;
    text-align: center;
    color: #5bc5d0;
    font-weight: 500;
    margin-top: 32px;
  }
}

// #region NO MESSAGES ////////////////
///////////////////////////////////////
///// NO MESSAGES SCREEN STYLES ///////
///////////////////////////////////////
.noMessagesHeader {
  width: 360px;
  color: #455a64;
  font-family: 'Metropolis';
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.noMessagesLabel {
  font-family: 'Metropolis';
  text-align: center;
  margin: 0;
}
// #endregion //////////////////////////

.headerIcon {
  display: flex;
  & img {
    width: 36px;
    height: 36px;
    padding-right: 14px;
    align-self: flex-end;
  }
}

// #region MESSAGES ///////////////////
///////////////////////////////////////
//////// MESSAGES SCREEN STYLES ///////
///////////////////////////////////////
.newMessagePromptTitle {
  padding-bottom: 7px;
}

.messageListLabel {
  display: none;
}

.providerHeader {
  padding: 15px 0 5px;
  width: 620px !important;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.providerHeader{
  padding: 15px 0 5px;
  width: 320px !important;
  text-align: left;
}

  
}

// #endregion //////////////////////////

// #region Login
/////////////////////////////
////// Forgot Password ////////
/////////////////////////////
.mobilePrompt {
  display: flex;
  justify-content: center;
  & h6 {
    font-size: 14px;
    margin-bottom: 12px;
    color: #546e7a;
  }
}
.signupMobilePrompt {
  @extend .mobilePrompt;
  & h6 {
    margin-bottom: 0;
  }
}
.forgotPasswordHeader {
  justify-content: center;
  margin-top: 5px;
  color: #546e7a;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.forgotPasswordText {
  justify-content: center;
  color: #90a4ae;
  font-size: 15px;
  padding: 20px 70px 25px 70px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 21px;
}
/////////////////////////////
////// Create New Password ////////
/////////////////////////////
.newPasswordHeader {
  justify-content: center;
  margin: 0 0 30px 0;
  color: #546e7a;
  font-size: 14px;
  font-weight: 550;
  letter-spacing: 0.5px;
}
// #endregion //////////////////////////

// #region Payments
/////////////////////////////
////// Payments ////////
/////////////////////////////
.appointmentProviderName {
  & h4 {
    color: #5bc5d0;
    font-size: 20px;
    font-family: 'Metropolis' !important;
    padding-top: unset;
    font-weight: 500;
  }
}
.paymentsDescriptionFirstLine {
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    margin-top: 1px;
  }
}
.pending{
  & h1{
    font-family: "Metropolis", sans-serif;
    font-size: 42px;
    font-weight: 100;
    color: #b0bec5;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .pending{
    margin-left: 5%;

    & h1{
      font-family: "Metropolis", sans-serif;
      font-size: 36px;
      font-weight: 300;
      color: #b0bec5;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }
  .paymentsDescriptionFirstLine {
    margin-top: 12%;
    margin-left: 5%;
    margin-right: 5%;
    & h4 {
      font-family: 'Metropolis', sans-serif;
      font-size: 16px;
      color: #78909c;
      line-height: 23px;
      font-weight: 300;
      margin-top: 1px;
    }
  }
}
.termsAndConditionsHeader {
  height: 35px;
  background-color: #5bc5d0;
  border-radius: 6px 6px 0 0;
  justify-content: center;
  align-items: center;
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 15px;
    color: #fff;
    line-height: 23px;
    font-weight: 300;
    text-align: center;
    justify-content: center;
    margin-top: 1px;
  }
}
.termsAndConditionsContent {
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 15px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    padding: 34px;
    margin-top: 1px;
  }
}
.confirmCancelContent {
  justify-content: center;
  position: relative;
  &:after {
    display: inline-block;
    content: '';
    width: 288px;
    border-bottom: 1px solid #eceff1;
    position: absolute;
    margin: 0 auto;
    top: 100%;
  }
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 15px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    padding: 21px;
    margin-top: 8px;
    text-align: center;
  }
}
.confirmCancelHeader {
  height: 45px;
  background-color: #eceff1;
  border-radius: 13px 13px 0 0;
  justify-content: center;
  align-items: center;
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 16px;
    color: #3a474e;
    line-height: 23px;
    font-weight: 510;
    text-align: center;
    justify-content: center;
    margin-top: 1px;
  }
}
.paymentsDescriptionLastLine {
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .paymentsDescriptionLastLine {
    margin-left: 5%;
    margin-right: 5%;
    & h4 {
      font-family: 'Metropolis', sans-serif;
      font-size: 16px;
      color: #78909c;
      line-height: 23px;
      font-weight: 300;
    }
  }
}
.modalPaymentSuccess {
  @extend .modalBalance;
  background-color: #5bd064;
}
.paymentHistoryNumber {
  & h3 {
    font-size: 13px;
    color: #90a4ae;
    font-weight: 400;
  }
}
.paymentHistoryReceipt {
  & h3 {
    font-size: 13px;
    color: #90a4ae;
    font-weight: bold;
  }
}
.paymentHistoryPlanDescription {
  & h3 {
    font-size: 13px;
    color: #90a4ae;
    font-weight: bold;
    margin: 0 auto;
  }
}
.paymentHistoryModalHeader {
  color: #90a4ae;
  & h4 {
    font-size: 13px;
    color: #90a4ae;
    font-family: 'Metropolis';
    font-weight: 400;
  }
}
.patientBalanceLeft {
  @extend .planInstallmentsLeft;
}
.planCompletedOnLeft {
  @extend .planInstallmentsLeft;
}
.planInstallmentsLeft {
  & h3 {
    line-height: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Metropolis';
  }
}
.patientBalanceRight {
  @extend .planInstallmentsRight;
}
.planCompletedOnRight {
  @extend .planInstallmentsRight;
}
.planInstallmentsRight {
  & h3 {
    font-size: 14px;
    color: #37474f;
    font-weight: bold;
  }
}
.patientItemLeft {
  display: flex;
  flex-basis: 500;
  & h3 {
    color: #78909c;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
}
.patientItemLeftPlan {
  @extend .patientItemLeft;
  flex-basis: '';
}
.patientItemRight {
  & h3 {
    color: #78909c;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Metropolis';
  }
}
.patientItemDate {
  & h3 {
    color: #78909c;
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'Metropolis';
  }
}
.modalBalance {
  width: 100%;
  height: 106px;
  background-color: #5bc5d0;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  & h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    line-height: 33px;
  }
}
.monthlyLabel {
  & h4 {
    color: #fff;
    font-size: 19px;
  }
}
.monthlyModalBalance {
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    line-height: 33px;
  }
}
.paymentPlan {
  justify-content: center;
  position: relative;
  top: -7px;
  & h3 {
    font-size: 14px;
    font-family: 'Metropolis';
    color: #78909c;
    text-align: center;
  }
}
.warningText {
  & h3 {
    font-size: 16px;
    font-weight: 500;
    color: #e22b41;
    text-align: center;
  }
}
.warningSubText {
  padding: 10px 80px 0px 80px;
  & h3 {
    font-size: 14px;
    font-weight: 400;
    color: #90a4ae;
    text-align: center;
  }
}
// #endregion ////////////////////

// #region myHealth ////////////////
///////////////////////////////////////
///// MY HEALTH HEADER STYLES ///////
///////////////////////////////////////
.myHealthDescriptionSubHeading {
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    margin-top: 1px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .myHealthDescriptionSubHeading {
    & h4 {
      font-family: 'Metropolis', sans-serif;
      font-size: 13px;
      color: #78909c;
      line-height: 23px;
      font-weight: 300;
      margin-top: 1px;
    }
  }
}
// #endregion ////////////////////

// #region Appointments ////
////////////////////////////
////// Appointments ////////
////////////////////////////
.appointments {
  & h3 {
    height: 32px;
    color: #455a64;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
}
.appointmentsDescription {
  & h6 {
    color: #78909c;
    font-size: 19px;
    margin-top: 1px;
    font-family: 'Metropolis', sans-serif;
    line-height: 23px;
    font-weight: 300;
  }
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    margin-top: 1px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .appointments{
    & h3 {
      margin-top: 22%;
      margin-left: 5%;;
      height: 32px;
      color: #455a64;
      font-family: 'Metropolis';
      font-size: 22px;
      font-weight: 500;
      line-height: 38px;
    }
  }
  .appointmentsDescription {
    & h6 {
      color: #78909c;
      font-size: 12px;
      margin-top: 1px;
      margin-left: 10%;
      font-family: 'Metropolis', sans-serif;
      line-height: 23px;
      font-weight: 300;
    }
    & h4 {
      font-family: 'Metropolis', sans-serif;
      font-size: 19px;
      color: #78909c;
      line-height: 23px;
      font-weight: 300;
      margin-top: 1px;
    }
  }
}
.today{
  & h1 {
    font-family: "Metropolis", sans-serif;
    font-size: 42px;
    font-weight: 100;
    color: #b0bec5;
    text-transform: capitalize;
    margin-bottom: 0;
}
}
.upcoming{
  & h1 {
    font-family: "Metropolis", sans-serif;
    font-size: 42px;
    font-weight: 100;
    color: #b0bec5;
    text-transform: capitalize;
    margin-bottom: 0;
}
}
.history{
  & h1 {
    font-family: "Metropolis", sans-serif;
    font-size: 42px;
    font-weight: 100;
    color: #b0bec5;
    text-transform: capitalize;
    margin-bottom: 0;
}
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .today{
    & h1 {
      font-family: "Metropolis", sans-serif;
      font-size: 30px;
      font-weight: 200;
      color: #b0bec5;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-left: 5%;
  }
  }
  .upcoming{
    & h1 {
      font-family: "Metropolis", sans-serif;
      font-size: 30px;
      font-weight: 200;
      color: #b0bec5;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-left: 5%;
  }
  }
  .history{
    & h1 {
      font-family: "Metropolis", sans-serif;
      font-size: 30px;
      font-weight: 200;
      color: #b0bec5;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-left: 10%;
  }
  }
}
.checkInOverlayHeader {
  padding: 0px 41px 24px 41px;
  line-height: 21px;
  color: #546e7a;
  font-size: 17px;
  text-align: center;
}
// #endregion ////////////////

// #region Add Appointment ////
///////////////////////////////
////// Add Appointment ////////
///////////////////////////////
.noAvailableHoursHeader {
  align-self: center;
  & h6 {
    color: #d2d5db;
  }
}
.bannerApptMsg, .bannerNotificationText {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  & h6 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    font-style: italic;
    margin: 25px 0 25px 0;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .bannerApptMsg, .bannerNotificationText {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    & h6 {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      font-style: italic;
      margin: 25px 0 25px 0;
    }
  }
}
.cancelIntelligentSchedulerMessage {
  @extend .bannerApptMsg;
  & h6 {
    font-style: normal;
  }
}
.selectPracticeHeader {
  padding: 12px;
  justify-content: center;
}
h4[id^='intelligentSchedulerQ'] {
  margin-top: 25px;
  margin-bottom: 18px;
  overflow-wrap: anywhere;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.changeSignupEmailTitle {
  display: flex;
  width: 100%;
  justify-content: center;
  & h6 {
    color: #5bc5d0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 0 32px 0;
  }
}
.requestApptSuccessMsg {
  display: flex;
  width: 100%;
  justify-content: center;
  & h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 0 40px 0;
  }
}
.changeSignupEmailTitle {
  display: flex;
  width: 100%;
  justify-content: center;
  & h6 {
    color: #5bc5d0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px;
    text-align: center;
  }
}
.checkYourSpam {
  border-top: 1px solid $main-color;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  & h6 {
    color: #8dbeff !important;
    font-family: $Metropolis;
    font-size: 14px;
    font-weight: 400;
  }
}
// #endregion /////////////////

// #region Appointment Prepayment ////
//////////////////////////////////////
////// Appointment Prepayment ////////
//////////////////////////////////////

.appointmentPaymentHeader {
  @extend .makePaymentsHeader;
}
.appointmentPaymentPrimaryHeader {
  @extend .makePaymentsPrimaryHeader;
  & h1 {
    margin-bottom: 16px;
  }
}
.appointmentPaymentPractice {
  @extend .makePaymentsPractice;
  & h3 {
    margin-bottom: 0;
    line-height: 1.4;
    font-weight: 400;
    font-size: 32px;
    width: 211px;
  }
}
.appointmentTime {
  & h4 {
    color: #78909c;
    font-weight: 500;
    font-family: 'Metropolis';
    font-size: 15px;
  }
}
.appointmentPaymentAddress {
  @extend .makePaymentsAddress;
}
.appointmentPaymentOffice {
  color: #546e7a;
  text-transform: uppercase;
  line-height: 0;
  height: 25px;
  margin-top: 18px;
  & h5 {
    font-weight: 700;
    width: 211px;
    white-space: nowrap;
  }
}
.appointmentPaymentCenterHeader {
  & h3 {
    font-family: 'Metropolis';
    color: #37474f;
    font-weight: 600;
    font-size: 26px;
  }
}
.appointmentPaymentCenterSub {
  width: 350px;
  & h3 {
    font-family: 'Metropolis';
    color: #78909c;
    font-size: 19px;
    font-weight: 300;
    line-height: 25px;
  }
}
.appointmentPaymentPayment {
  @extend .makePaymentsChooseType;
  & div[id*='paymentsBtnsNoOnline'] {
    width: 600px;
    text-align: center;
    margin-left: -200px;
  }
}
.appointmentPaymentChooseType {
  @extend .makePaymentsChooseType;
}
.paymentPatientFeedbackPopupTitle {
  color: red;
  font-size: 17px;
  font-weight: 600;
  margin-top: 5%;
  font-family: 'Metropolis';
}
.paymentPatientFeedbackPopupDescription {
  font-size: 17px;
  font-weight: 500;
  margin-top: 5%;
  font-family: 'Metropolis';
}

// #endregion ////////////////////

// #region Add Appointment ///////
//////////////////////////////////
////// Add Appointment ///////////
//////////////////////////////////

.addAppointmentHeader {
  @extend .makePaymentsHeader;
}
.addAppointmentPrimaryHeader {
  & h1 {
    font-size: 32px;
    color: #455a64;
    font-weight: 500;
    margin-top: 0;
    line-height: 0.95;
    margin-bottom: 21px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .addAppointmentPrimaryHeader {
    display: none;
  }
}
.addAppointmentOptionHeader {
  & h4 {
    color: #b0bec5;
    font-weight: 300;
    font-size: 42px;
    font-family: 'Metropolis';
    margin-bottom: 10px;
  }
}

// #endregion ////////////////////

// #region Forms /////////////////
//////////////////////////////////
////// Forms /////////////////////
//////////////////////////////////

// addAppointmentHeader { @extend makePaymentsHeader }
.formsPrimaryHeader {
  @extend .makePaymentsPrimaryHeader;
  & h1 {
    margin-bottom: 8px;
  }
}
.formsSecondaryHeader {
  @extend .paymentsDescriptionFirstLine;
}
.formHistoryHeader {
  & h4 {
    color: #b0bec5;
    font-weight: 300;
    font-size: 42px;
    font-family: 'Metropolis';
    width: 800px;
    white-space: nowrap;
    margin-bottom: 0;
    overflow: hidden;
    line-height: 2.3rem;
    text-overflow: ellipsis;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.formsSecondaryHeader {
  width: 90%;
   }
.formHistoryHeader{
  & h4 {
    margin-left: 3%;
    font-size: 24px;
  }
}
}

// addAppointmentOptionHeader { & h4 { color: #B0BEC5; font-weight: 300; font-size: 42; font-family: "Metropolis"; margin-bottom: 10 } }

// #endregion ////////////////////

// #region Add Appointment /////////
////////////////////////////////////
////// Appointment Checkout ////////
////////////////////////////////////
.appointmentPaymentCheckOutPractice {
  @extend .appointmentPaymentPractice;
  & h3 {
    margin-bottom: 0;
  }
  height: 25px;
}
.appointmentPaymentTime {
  & h3 {
    font-size: 14px;
    color: #78909c;
    font-weight: 500;
    margin-bottom: 14px;
  }
}
// #endregion ///////////////////////

// #region Available Hours //////////
/////////////////////////////////////
////// Available Hours //////////////
/////////////////////////////////////
.availableHoursAddAppointment {
  & h6 {
    height: 13px;
    color: #5bc5d0;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
}
.availableHoursAvailableHours {
  & h3 {
    height: 32px;
    color: #455a64;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .availableHoursAvailableHours {
    & h3 {
      height: 32px;
      color: #455a64;
      font-family: 'Metropolis';
      font-size: 26px;
      margin-left: 13%;
      font-weight: 500;
      line-height: 38px;
    }
  }
}
// #endregion ////////////////////////////////////

// #region Make Payments & One Time Payment & Shop Payment
/////////////////////////////////////////////////////
// Make Payments & One Time Payment & shop Payment //
////////////////////////////////////////////////////
.makePaymentsShopHeader {
  margin-bottom: 20px;
  & h4 {
    color: #546e7a;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.17;
    font-family: 'Metropolis';
  }
}
.makePaymentsPlanHeader {
  margin-top: 20px;
  & h4 {
    color: #546e7a;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.17;
    font-family: 'Metropolis';
  }
}
.makePaymentsPlanDetails {
  @extend .makePaymentsAddress;
}
.successfulPaymentHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  & h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 0 40px 0;
  }
}
.reconcileAllergiesHeader,.reconcileMedicationHeader{
  & h2{
    font-size: xxx-large;
    font-weight: bold;
    margin-top: 12px;
  }
}
.successfulDeleteHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  & h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 20px 40px 20px;
    text-align: center;
  }
}
.makePaymentsCancelHeader {
  display: flex;
  width: 100%;
  height: 174px;
  border-radius: 10px 10px 0px 0px;
  justify-content: center;
  background-color: #51beca;
  & h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    width: 197px;
    line-height: 1.3;
    text-align: center;
    margin-top: 104px;
  }
}
.makePaymentsHeader {
  display: flex;
  width: 1080px;
  & h3 {
    color: #5bc5d0;
    font-size: 13px;
    align-self: flex-end;
  }
}
.makePaymentsPrimaryHeader {
  & h1 {
    font-size: 32px;
    color: #455a64;
    font-weight: 500;
    margin-top: 0;
    line-height: 0.95;
    margin-bottom: 21px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .makePaymentsHeader {
    margin-top: 7%;
    width: 100%;
  }
  .makePaymentsPrimaryHeader {
    & h1 {
      font-size: 24px;
      color: #455a64;
      font-weight: 500;
      margin-top: 0;
      line-height: 0.95;
    }
  }
}
.makePaymentsPractice {
  & h3 {
    font-size: 20px;
    color: #5bc5d0;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 17px;
    position: relative;
    width: 231px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.makePaymentsAddress {
  line-height: 1.5;
  width: 165px;
  & h4 {
    line-height: 1.4;
    font-size: 12px;
    color: #546e7a;
    font-weight: 400;
    font-family: 'Metropolis';
  }
}
.makePaymentsChooseType {
  & h3 {
    font-size: 21px;
    color: #546e7a;
    font-weight: 500;
    margin-top: 0;
    font-family: 'Metropolis';
    margin-bottom: 10px;
  }
}
.makePaymentsTypeHeader {
  & h4 {
    text-transform: capitalize;
    font-size: 14px;
    color: #597583;
    font-weight: 500;
    margin-top: 0;
    font-family: 'Metropolis';
    line-height: 2.5;
  }
}
.makePaymentsTypeHeaderSelected {
  @extend .makePaymentsTypeHeader;
  & h4 {
    color: #51bdca;
  }
}
.makePaymentsPreview {
  justify-content: center;
  text-transform: uppercase;
  background-color: #5bc5d0;
  height: 34px;
  align-items: center;
  color: #fff;
  font-family: 'Metropolis';
  font-size: 11px;
  & h4 {
    font-weight: 500;
  }
}
// #endregion
.languageDropdown {
  cursor: pointer;
  color: #5bc5d0;
  font-size: 15px;
  padding-right: 10px;
}
.userSection {
  @extend .languageDropdown;
  font-weight: 500;
  padding-left: 10px;
}
// #region PATIENT PORTAL
/////////////////////////////
////// PATIENT PORTAL ///////
/////////////////////////////
.patientPortalSubHeading {
  width: 600px;
}
.patientPortalHeader {
  width: 400px;
}
// #endregion

// #region SHOPS
/////////////////////////////
////// SHOPS ///////
/////////////////////////////
.shopMainHeader {
  justify-content: center;
  letter-spacing: -1px;
}
.shopMainHeaderLeft {
  letter-spacing: -1px;
  line-height: 32px;
}
.shopSubHeader {
  margin-bottom: 50px;
  justify-content: center;
  font-size: 20px;
  color: #78909c;
  & h5 {
    margin: 0;
    font-weight: 400;
  }
}
.retailOptionsHeader {
  margin-top: 46px;
}
.shopSubHeaderLeft {
  & h4 {
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    color: #78909c;
    line-height: 23px;
    font-weight: 300;
    margin-top: 1px;
  }
}
img[id*='shoppingBasketEmpty'] {
  height: 250px;
}
.noRetailOptions {
  margin-top: 50px;
  justify-content: center;
  color: #607d8b;
  & h4 {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
  }
}
.noRetailOptionsSubText {
  justify-content: center;
  & h6 {
    font-size: 14px;
    color: #90a4ae;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.shopSubHeader{
  text-align: center;
  margin-top: 10%;
}
img[id*='shoppingBasketEmpty'] {
  height: 170px;
}
.noRetailOptionsSubText{
  text-align: center;
}
}
// #region CHECK IN APPOINTMENT
////////////////////////////////////
////// CHECK IN APPOINTMENT ///////
//////////////////////////////////
.infoCheckInSectionText {
  & label {
    height: 12px;
    width: 29px;
    color: #546e7a;
    font-family: 'Metropolis';
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
  }
}
.headingCheckIn {
  & h2 {
    color: #455a64;
    margin: 0;
    font-size: 32px;
    font-family: 'Metropolis';
    font-weight: 500;
  }
}
.appointmentCheckInHeading {
  & h5 {
    margin: 0;
    font-size: 13px;
    color: #5bc5d0;
    font-weight: 500;
    font-family: 'Metropolis';
  }
}
.nameInfoCheckInSectionText {
  @extend .infoCheckInSectionText;
}
.addressInfoCheckInSectionText {
  @extend .infoCheckInSectionText;
}
.detailsInfoCheckInSectionText {
  @extend .infoCheckInSectionText;
}
.documentsInfoCheckInSectionText {
  @extend .infoCheckInSectionText;
}
.checkInDocumentSectionInsuranceUpload {
  & h6 {
    color: #5bc5d0 !important;
  }
}
.confirmSSNOrDLMsg {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  & h6 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 0 25px 0;
  }
}
// #endregion

// #region Profile And Setting
////////////////////////////////////
////// Profile And Setting /////////
//////////////////////////////////
.profileSettingsMainSubHeader {
  grid-row: 2;
  grid-column: span 2;
  & h4 {
    margin-top: 1px;
    margin-bottom: 46px;
    color: #78909c;
    font-size: 19px;
    line-height: 23px;
    font-family: 'Metropolis';
    font-weight: 300;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .profileSettingsMainSubHeader {
    grid-row: 2;
    grid-column: span 2;
    & h4 {
      margin-top: 35px;
      margin-bottom: 6px;
      margin-left: 5px;
      color: #78909c;
      font-size: 19px;
      line-height: 23px;
      font-family: 'Metropolis';
      font-weight: 300;
    }
  }
}
.profileSettingsUserHeader {
  justify-content: center;
  width: 100%;
  & h4 {
    font-family: 'Metropolis';
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #37474f;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & h5 {
    margin: 0;
    height: 100%;
    font-weight: 500;
    color: #607d8b;
    font-size: 12px;
    line-height: 15px;
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    text-align: center;
  }
}

.profileSettingsContentHeader {
  & h4 {
    color: #b0bec5;
    font-family: 'Metropolis';
    font-size: 42px;
    font-weight: 200;
    line-height: 50px;
  }
}
.profileSettingsFormSectionheader {
  & h3 {
    color: #37474f;
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }
  padding-top: 3rem;
}
#profileSettingsRightContent h3 {
  color: #37474f;
  font-family: 'Metropolis';
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding-top: 3rem;
}
#profileSettingsRightContent h3:first-of-type {
  color: #37474f;
  font-family: 'Metropolis';
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding-top: 20px;
}
#profileSettingsRightContent [id*='InfoText'] h6 {
  color: #90a4ae;
  font-family: 'Metropolis';
  font-size: 13px;
  line-height: 19px;
  font-weight: 300;
}
#profileSettingsRightContent [id*='InfoText'] label {
  height: 12px;
  width: 29px;
  color: #546e7a;
  font-family: 'Metropolis';
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
}
#profileSettingsRightContent [id*='InfoText'] input {
  height: 30px;
}
#profileSettingsRightContent #demographics h5 {
  color: #90a4ae;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  font-family: 'Metropolis';
  text-transform: uppercase;
  margin-bottom: 15px;
}
#profileSettingsIdentity h3:first-of-type {
  padding-top: 0;
}
.profileSettingsIdentity {
  width: 100%;
  padding-top: 0;
}
.profileSettingsInsurance {
  width: 100%;
  padding-top: 0;
  & h3 {
    padding-top: 0px !important;
  }
}
.profileSettingsNewCard {
  & h6 {
    font-size: 26px;
    line-height: 32px;
    color: #37474f;
    font-family: 'Metropolis';
    font-weight: 600;
    padding-bottom: 24px;
    padding-top: 60px;
  }
}
.profileSettingsExistingCreditCardNumber {
  & h5 {
    color: #37474f;
    font-family: 'Metropolis';
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin: 0;
  }
  margin-right: 10px;
}
.profileSettingsDefaultCreditCard {
  & h5 {
    color: #90a4ae;
    font-family: 'Metropolis';
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    margin: 0 auto;
    align-self: center;
  }
  margin-right: 5px;
  background-color: #e7ecee;
  border-radius: 12px;
  width: 75px;
  height: 18px;
  align-self: center;
}
.profileSettingsExpiredCreditCard {
  & h5 {
    color: #e22b41;
    font-size: 11px;
    font-family: 'Metropolis';
    line-height: 13px;
    margin: 0 auto;
    align-self: center;
    font-weight: 600;
  }
  background-color: rgba(226, 43, 65, 0.2);
  border-radius: 12px;
  width: 55px;
  height: 18px;
  align-self: center;
}
.profileSettingsBillingAddressHeader {
  padding-top: 40px;
  & h5#profileSettingsBillingAddressHeader1 {
    color: #90a4ae;
    text-transform: uppercase;
    font-family: 'Metropolis';
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
}
// #endregion

.passwordPrompt {
  width: 303px;
}
// cancel appoinmtemnt modal
.cancelAppointmentHeader {
  width: 100%;
  height: 50px;
  border-radius: 10px 10px 0px 0px;
  justify-content: center;
  background-color: #5bc5d0;
  & h4 {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: 5px;
    margin: 0 auto;
    align-self: center;
  }
}
.cancelAppointmentFee {
  &:after {
    display: inline-block;
    content: '';
    width: 288px;
    border-bottom: 1px solid #b0bec5;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 20%;
  }
  & h4 {
    font-size: 18px;
    color: #546e7a;
    font-weight: 400;
    margin: 0 auto;
    padding: 25px 0;
  }
}
.cancelAppointmentWhy {
  & h4 {
    font-size: 18px;
    color: #546e7a;
    font-weight: 400;
    margin: 0 auto;
    padding: 20px 0;
    width: 277px;
    text-align: center;
  }
}

.noProviderMessageLabel {
  & h3 {
    font-size: 26px;
    color: #a6b6bd;
    font-weight: 400;
    margin: 0 auto;
    padding: 20px 0;
    width: 360px;
    text-align: center;
    line-height: 1.3;
  }
}

.cancelApptSuccessMsg {
  display: flex;
  width: 100%;
  justify-content: center;
  & h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    margin: 25px 0 40px 0;
  }
}
// end cancel appointment modal
.oneTimePaymentSelectDateLabel {
  & h6 {
    color: #546e7a;
    font-size: 10px;
    text-align: left;
    font-family: 'Metropolis', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.06em;
  }
}
.guestAppointmentSuccessfulThanks {
  width: 330px;
  text-align: center;
  margin: 10px auto;
  margin-bottom: 40px;
  & h4 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
  }
}
.guestGetStartedTitle {
  margin: 0 auto;
  & h1 {
    color: #37474f;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 300;
    line-height: 37px;
    text-align: center;
    margin-top: 30px;
    padding: 0 20px;
  }
}
.guestGetStartedSubTitle {
  margin: 0 auto;
  & h5 {
    color: #90a4ae;
    font-family: 'Metropolis';
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    max-width: 700px;
    padding: 0 20px;
  }
}
.guestPaymentSuccessfulAmount {
  min-height: 70px;
  & h1 {
    color: #455a64;
    font-family: 'Metropolis';
    font-size: 49px;
    font-weight: 500;
    margin: 0 auto;
    height: 70px;
  }
}
.guestPaymentSuccessEndingIn {
  min-height: 20px;
  & h6 {
    margin: auto !important;
    color: #546e7a;
    font-size: 12px;
  }
}
.guestPaymentSuccessRef {
  min-height: 20px;
  & h6 {
    margin: 0 auto;
    color: #5bd064;
    font-size: 20px;
    font-weight: 600;
    height: 25px;
  }
}
.surveyCardThankYou {
  & h3 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Metropolis';
    color: #78909c;
    margin: 0 auto;
    margin-bottom: -5px;
  }
}
.surveyCardDate {
  @extend .surveyCardThankYou;
  & h3 {
    font-size: 28px;
    font-weight: 100;
  }
}
.surveyCardTime {
  @extend .surveyCardThankYou;
  grid-row: 1;
  & h3 {
    font-size: 15px;
    font-weight: 600;
  }
}
.surveysHeading {
  & h3 {
    color: #455a64;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 500;
  }
}
.surveysSubheading {
  & h3 {
    font-size: 19px;
    font-family: 'Metropolis';
    color: #78909c;
  }
  margin-bottom: 40px;
}
.surveysCheckoutHeading {
  & h3 {
    color: #5bc5d0;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 500;
  }
}
.surveysCheckoutSubheading {
  & h3 {
    font-size: 32px;
    font-weight: 500;
    font-family: 'Metropolis';
    color: #455a64;
  }
  margin-bottom: 40px;
}
.surveyThankYouFeedback {
  & h6 {
    margin: 0 auto;
    color: #78909c;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
  }
  border-bottom: 1px solid #78909c61;
}
.surveysSatisfiedThankYou {
  & h3 {
    margin: auto;
    padding-top: 25px;
    padding-bottom: 15px;
  }
}
.surveyQuestionNumber {
  & h6 {
    font-size: 12px;
    font-family: 'Metropolis';
    font-weight: 600;
    color: #546e7a;
  }
}
.surveyWhyNotSatisfied {
  @extend .surveysSatisfiedThankYou;
  margin-bottom: 20px;
  & h6 {
    margin: auto;
    margin-top: 10px;
    color: #37474f;
    font-size: 21px;
    font-weight: 500;
  }
}
.emptySurveySelectionHeader {
  & h6 {
    font-size: 16px;
    font-weight: 300;
    color: #b1bfc5;
  }
}
.guestPayMatchPastStatement {
  & h6 {
    font-size: 12px;
    color: #546e7a;
    text-align: center;
    padding: 0 10px;
  }
}
.guestPaymentReceiptReceipt {
  flex: 1;
}
.guestPaymentReceiptRef {
  flex: 1;
}
.modalHeaderBase {
  margin: 20px 0px 40px;
  & h4 {
    font-size: 90px;
    font-weight: 500;
    font-family: 'Metropolis';
    margin: 0 auto;
    padding: 8px 0;
    color: #5bc5d0;
    text-align: center;
  }
}
.guestPayErrorModalHeader {
  @extend .modalHeaderBase;
}
.delegateSectionModalHeader {
  @extend .modalHeaderBase;
}
.signUpErrorModalHeader {
  @extend .modalHeaderBase;
}
.cashPayModalHeader {
  @extend .modalHeaderBase;
}
.modalContentBase {
  & h4 {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Metropolis';
    color: #fff;
    text-align: center;
  }
}
.guestPayErrorModalContent {
  @extend .modalContentBase;
}
.delegateSectionModalContent {
  @extend .modalContentBase;
}
.signUpErrorModalContent {
  @extend .modalContentBase;
}
.cashPayModalContent {
  @extend .modalContentBase;
}
.delegateForHeader {
  height: 21px;
  align-items: center;
  & h6 {
    color: #5bc5d0;
    cursor: pointer;
    font-size: 15px;
    transition: color 0.5s;
    font-weight: 500;
    font-family: 'Metropolis';
    padding-left: 10px;
    text-transform: capitalize;
    background-color: transparent;
  }
}
.guestPaySubheading {
  & h6 {
    text-align: center;
    font-size: 14px;
  }
}
.guestAppointmentSuccessfulHeader {
  margin-bottom: 20px;
  min-height: 200px;
}
.createVisitSummaryHeaderExportAs {
  padding-top: 1.5rem;
}
.createVisitSummaryHeaderSendTo {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.delegateScreenHeading {
  & h2 {
    color: #ffffff !important;
    letter-spacing: normal;
    font-size: 30px;
    font-family: 'Metropolis';
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    width: 100vw;
  }
}
.delegateScreenSubheading {
  & h4 {
    font-weight: 100;
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .delegateScreenSubheading {
    & h4 {
      margin-top: 55px !important;
    }
  }
}
.profileSettingsExpirationDateValueExpired {
  & h5 {
    color: #e22b41 !important;
  }
}
.guestRescheduleAppointmentHeading, .guestRegisterHeading, .guestCheckinHeading {
  & h3 {
    text-align: center;
  }
}
.guestRegisterSubheading, .guestRescheduleAppointmentSubheading, .guestCheckinSubheading {
  & h6 {
    text-align: center;
  }
}
.chooseADifferentDate {
  & h5 {
    font-size: 12px;
    color: #5bc5d0;
    font-weight: 600;
  }
}
.availableHoursDatePickerIcon {
  & img {
    width: 25px;
    height: 25px;
    position: relative;
    top: 5px;
    margin: 5px 0px 0px 5px;
  }
}
.manageProfilesHeader {
  & h1 {
    color: #455a64;
    font-size: 32px;
    font-weight: 500;
  }
}
.manageProfilesSubHeader {
  & h4 {
    font-family: 'Metropolis';
    color: #78909c;
    font-size: 19px;
    font-weight: 300;
  }
}
.manageProfilesManagerName {
  & h4 {
    color: #b0bec5;
    font-size: 42px;
    font-weight: 300;
    margin: 40px 0px 30px 0px;
  }
}
.manageProfilesManagerDemographicBase {
  & h4 {
    color: #455a64;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 600;
  }
  grid-row: 1;
}
.manageProfilesManagerDemographicValue {
  @extend .manageProfilesManagerDemographicBase;
  display: inline-grid;
  justify-content: end;
  & h4 {
    font-weight: 400;
  }
}
.manageProfilesManagerDob {
  @extend .manageProfilesManagerDemographicValue;
}
.manageProfilesManagerDobLabel {
  @extend .manageProfilesManagerDemographicBase;
}
.manageProfilesManagerGender {
  @extend .manageProfilesManagerDemographicValue;
}
.manageProfilesManagerGenderLabel {
  @extend .manageProfilesManagerDemographicBase;
}
.manageProfilesManagerEmail {
  @extend .manageProfilesManagerDemographicValue;
}
.manageProfilesManagerEmailLabel {
  @extend .manageProfilesManagerDemographicBase;
}
.manageProfilesManagerPhoneType {
  @extend .manageProfilesManagerDemographicValue;
}
.manageProfilesManagerPhoneTypeLabel {
  @extend .manageProfilesManagerDemographicBase;
}
.manageProfilesManagerPhoneNumber {
  @extend .manageProfilesManagerDemographicValue;
}
.manageProfilesManagerPhoneNumberLabel {
  @extend .manageProfilesManagerDemographicBase;
}
.manageProfilesDelegatesIntro {
  margin: 20px 0px;
  & h4 {
    color: #90a4ae;
    font-family: 'Metropolis';
    font-size: 15px;
  }
}
.manageProfilePracticeExpanderHeaderPracticeName {
  & h4 {
    font-size: 17px;
    font-weight: 600;
    color: #546e7a;
  }
  grid-column: 2;
}
.manageProfilePracticeExpanderHeaderPracticeExpiration {
  & h5 {
    font-family: 'Metropolis';
    font-size: 14px;
    font-weight: 300;
    color: #78909c;
  }
  grid-column: 2;
  padding: 5px 0px 0px 2px;
}
.manageDelegatePracticeExpanderHeaderPracticeExpiration {
  @extend .manageProfilePracticeExpanderHeaderPracticeExpiration;
  padding: 0px;
}
.manageProfilePracticeExpanderChevron {
  & h4 {
    font-size: 13px;
    font-weight: 500;
    color: #90a4ae;
  }
  cursor: pointer;
  grid-column: 4;
}
.manageProfilePracticeExpanderHeaderPracticePermissionHeading {
  grid-column: span 3;
  & h5 {
    font-family: 'Metropolis';
    font-size: 18px;
    font-weight: 600;
    color: #455a64;
    margin: 15px 0px;
  }
}
.manageProfilePracticeExpanderPermission {
  padding: 0 5px;
  & h5 {
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 500;
    color: #455a64;
    margin: 0px !important;
  }
}
.mergeDelegateProfileMaybeConfirmationModalText {
  background-color: #455a64;
  & h4 {
    font-size: 18px;
    font-family: 'Metropolis';
    font-weight: 400;
    color: #fff;
    padding: 0px 30px;
    margin-top: 1px;
    margin-bottom: 40px;
  }
}
.mergeDelegateProfileMaybeConfirmationModalHeader {
  @extend .modalHeaderBase;
  padding: 50px;
  margin: 0;
  background-color: #455a64;
  border-radius: 10px 10px 0px 0px;
}
.disconnectDelegateProfileMaybeConfirmationModalText {
  @extend .mergeDelegateProfileMaybeConfirmationModalText;
}
.disconnectDelegateProfileMaybeConfirmationModalHeader {
  @extend .mergeDelegateProfileMaybeConfirmationModalHeader;
}
.profileSettingsMainHeader {
  grid-column: span 2;
  grid-row: 1;
}
.profileSettingsNameInfoText {
  & h6 {
    font-size: 14px;
  }
}
.profileSettingsAddressSectionInfoText {
  @extend .profileSettingsNameInfoText;
}
.documentInfoText {
  @extend .profileSettingsNameInfoText;
}
.profileSettingsEmailInfoText {
  @extend .profileSettingsNameInfoText;
}
.profileSettingsNotificationInfoText {
  @extend .profileSettingsNameInfoText;
}
.profileSettingsPasswordInfoText {
  @extend .profileSettingsNameInfoText;
}
.twofaInfoText{
  & h6 {
    font-size: 14px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
 .twofaInfoText{
  display: none;
  }
  .profileSettingsPasswordInfoText{
  display: none;
  }
  .profileSettingsEmailInfoText {
  display: none;
  }
}
.logoutCountdownModalHeader {
  justify-content: center;
  & h1 {
    text-align: center;
    font-size: 72px;
    font-weight: 500;
    color: #5bc5d0;
    margin-top: 0;
    line-height: 90px;
  }
}
.logoutCountdownModalSubHeader {
  display: inline-block;
  justify-content: center;
  padding: 0px 15px 15px;
  & h5 {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
    display: inline-block;
    line-height: 25px;
  }
}
.clientSideModalTimer {
  & h5 {
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
    margin: 0 5px;
  }
}
.telehealthTitle {
  & h2 {
    margin-top: 75px;
    height: 32px;
    width: 285px;
    color: #37474f;
    font-family: 'Metropolis';
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
  }
}
.telehealthText {
  & h3 {
    margin-top: 35px;
    height: 99px;
    width: 536px;
    color: #546e7a;
    font-family: 'Metropolis';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }
}
.navPageTitle {
  & h3 {
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: $gray-8;
    text-wrap: nowrap;
  }
}
$breakpoint-tablet:750px;
@media (max-height: $breakpoint-tablet)
{
  .navPageTitle {
    & h3 {
      font-family: 'Metropolis';
      font-family: 'Metropolis';
      font-weight: 530;
      font-size: 26px;
      line-height: 18px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: $gray-8;

    }
  }
}
$breakpoint-tablet:700px;
@media (max-height: $breakpoint-tablet)
{
  .navPageTitle {
    & h3 {
      font-family: 'Metropolis';
      font-family: 'Metropolis';
      font-weight: 530;
      font-size: 22px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: $gray-8;

    }
  }
}
$breakpoint-tablet:1648px;
@media (max-width: $breakpoint-tablet)
{
  .navPageTitle {
    & h3 {
      font-family: 'Metropolis';
      font-family: 'Metropolis';
      font-weight: 530;
      font-size: 22px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: $gray-8;
      text-wrap: nowrap;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .navPageTitle {
    & h3 {

      font-family: 'Metropolis';
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: $gray-8;
      text-wrap: wrap;
    }
  }
}
.userName {
  & h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #aab9c1;
  }
}
.sidebarTitle {
  & h6 {
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #aab9c1;
  }
}
.practiceSelectTitle {
  & h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #455a64;
  }
  display: flex;
  align-items: center;
  background-color: #e8e9e9;
  padding-left: 22px;
  padding-right: 12px;
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  height: 36px;
}
.bannerNotificationHeader{
  & h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #fff;
    width: 100%;
    text-wrap: pretty;
    text-align: center;
  }
  display: flex;
  align-items: center;
  background-color: #5bc5d0;
  padding: 1px 12px 1px 22px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  z-index: 99999;
  width: 60%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 20%;
  min-height: 20px;
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .bannerNotificationHeader{
    & h6 {
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    width: 70%;
    left: 10%;
    margin: 0 10px
  }
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .userName {
    & h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 8px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #aab9c1;
    }
  }
  .practiceSelectTitle {
   display: none;
  }
}
$breakpoint-tablet: 500px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectTitle {
    & h6 {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: #455a64;
    }
    display: flex;
    align-items: center;
    background-color: #e8e9e9;
    padding-left: 22px;
    padding-right: 12px;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    height: 36px;
  }
}
$breakpoint-tablet: 400px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectTitle {
    & h6 {
      font-weight: 500;
      font-size: 7px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: #455a64;
    }
    display: flex;
    align-items: center;
    background-color: #e8e9e9;
    padding-left: 18px;
    padding-right: 12px;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    height: 30px;
  }
}
$breakpoint-tablet: 360px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectTitle {
    & h6 {
      font-weight: 400;
      font-size: 5px;
      line-height: 13px;
      letter-spacing: 0.01em;
      color: #455a64;
    }
    display: flex;
    align-items: center;
    background-color: #e8e9e9;
    padding-left: 15px;
    padding-right: 10px;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    height: 30px;
  }
}
.nextAppointmentTitleWrapper,
.lastVisitTitleWrapper {
  // font-family: 'Proxima Nova';
  margin: 0;
  padding: 16px 12px;
  border-bottom: 1px solid #e8e9e9;
  & h3 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #424242;
  }
}
.lastVisitTitle,
.nextAppointmentTitle,
.historyTitle,
.myCareTeamTitle {
  & h3 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #424242;
  }
}

.nextAppointmentDate,
.nextAppointmentTime,
.nextAppointmentVisitType,
.nextAppointmentProvider,
.nextAppointmentLocation,
.lastVisitDate,
.lastVisitVisitType,
.lastVisitProvider,
.lastVisitCurrentBalance,
.lastVisitDueDate {
  flex: 4;
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #424242;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .nextAppointmentDate,
  .nextAppointmentTime,
  .nextAppointmentVisitType,
  .nextAppointmentProvider,
  .nextAppointmentLocation,
  .lastVisitDate,
  .lastVisitVisitType,
  .lastVisitProvider,
  .lastVisitCurrentBalance,
  .lastVisitDueDate {
    flex: 4;
    & h6 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #424242;
    }
  }
}
.nextAppointmentDateValue,
.nextAppointmentTimeValue,
.nextAppointmentVisitTypeValue,
.nextAppointmentProviderValue,
.nextAppointmentLocationValue,
.lastVisitDateValue,
.lastVisitVisitTypeValue,
.lastVisitProviderValue,
.lastVisitCurrentBalanceValue,
.lastVisitDueDateValue {
  flex: 6;
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #424242;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .nextAppointmentDateValue,
  .nextAppointmentTimeValue,
  .nextAppointmentVisitTypeValue,
  .nextAppointmentProviderValue,
  .nextAppointmentLocationValue,
  .lastVisitDateValue,
  .lastVisitVisitTypeValue,
  .lastVisitProviderValue,
  .lastVisitCurrentBalanceValue,
  .lastVisitDueDateValue {
    flex: 6;
    & h6 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #424242;
    }
  }
}
.lastVisitCurrentBalanceValue,
.lastVisitDueDateValue {
  & h6 {
    color: $main-color;
  }
}
.noAppointmentScheduled {
  & h6 {
    color: #3c4549;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .noAppointmentScheduled {
    & h6 {
      color: #3c4549;
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}
.historyAppointmentName {
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #3c4549;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .historyAppointmentName {
    & h6 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #3c4549;
    }
  }
}
.historyAppointmentDoB {
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #424242;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .historyAppointmentDoB {
    & h6 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #424242;
    }
  }
}
.historyAppointmentTime {
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3c4549;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .historyAppointmentTime {
    & h6 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #3c4549;
    }
  }
}
.historyAppointmentTime2 {
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 20px;
    line-height: 14px;
    color: #3c4549;
  }
}
.careTeamProviderName {
  padding-top: 4px;
  & h3 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3c4549;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .careTeamProviderName {
    padding-top: 4px;
    & h3 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #3c4549;
    }
  }
}

.careTeamSpecialtyName{
 & h6 {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #777D80;
 }
}
.loginOTPModalHeader,
.twofaVerifyHeader {
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 18px;
  & h4 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $black;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .loginOTPModalHeader, .twofaVerifyHeader{
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 18px;
    & h4 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .loginOTPModalHeader, .twofaVerifyHeader{
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 18px;
    & h4 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
    }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .loginOTPModalHeader, .twofaVerifyHeader{
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 18px;
    & h4 {
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black
    }
  }
}
.loginOTPModalDesc{
  justify-content: center;
  padding-top: 18px;
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: $black;
  }
}
.twofaVerifyDesc,
.twofaVerifyDesc2 {
  justify-content: center;
  text-align: center;
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: $black;
  }
}
.twofaModalHeader{
  & h4 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $white;
  }
  padding-block: 16px;
}
.twofaModalDesc, .completeRegistrationDesc{
  & h6 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $white;
  }
}
.completeRegistrationDesc{
  margin-top: 12px;
  text-align: center;
}
.exclamationMark{
  & h2 {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    color: $main-color;
  }
  cursor: default;
  user-select: none;
}
@media screen and (max-width: 460px) {
  .addAppointmentOptionHeader {
    margin-top: 10%;
  }
  .addAppointmentHeader {
    width: 90%;
    margin-top: 10%;
  }
  .makePaymentsPrimaryHeader {
    & h1 {
      margin: 15px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px){
  .makePaymentsPrimaryHeader {
    & h1 {
      margin-left: 15px;
    }
  }

}