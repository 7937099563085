@import '../../styleguide/_variables';

.container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 4px $box_shadow_nav;
  padding: 0;
  position: fixed;
  background-color: $white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile){
  .containerBanner {
  height: 80px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 4px $box_shadow_nav;
  padding: 0;
  position: fixed;
  background-color: $white;
  top: 0.5%;
  left: 0;
  right: 0;
  z-index: 11;
  }
}
.f1 {
  display: flex;
  flex: 1;
}
.navContainer{
  display: flex;
  flex-direction: row;
  height: 88px;
  position: sticky;
  top: 72px;
  background-color: white;
  z-index: 8;
}
span[class*='hamburger-inner']{
  background-color: $main-color
} span[class*='hamburger-inner']::before {
  background-color: $main-color;
}
span[class*='hamburger-inner']::after {
  background-color: $main-color;
}
.menu {
  @extend .f1;
  justify-content: flex-start;
  align-items: center;
  & img {
    vertical-align: middle;
    width: 40px;
  }
  & button[class*='hamburger']:hover {
    opacity: 1 !important;
  }
  & button[class*='hamburger']:hover > & span[class*='hamburger-inner'],
  & button[class*='hamburger']:hover > & span[class*='hamburger-inner']::before,
  & button[class*='hamburger']:hover > & span[class*='hamburger-inner']::after {
    background-color: $gray-8;
  }
  & span[class*='hamburger-inner']::before {
    top: -8px;
    width: 27px;
    height: 3px;
    background-color: $main-color;
  }
  & span[class*='hamburger-inner']::after {
    top: -16px;
    width: 27px;
    height: 3px;
    bottom: -8px;
    background-color: $main-color;
  }
  & span[class*='hamburger-inner'] {
    width: 27px;
    height: 3px;
    background-color: $main-color;
  }

  & button {
    outline: none;
  }
  & div[class*='active'] {
    animation: fadeIn 0.7s;
    background-color: white;
    padding: 0 10px 10px 0;
    box-shadow: 0 1px 4px $box_shadow_gray;
  }
}
.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-right: 1px solid #E8E9E9;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .logoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 61px;
    border-right: 1px solid #E8E9E9;
  }
}
.practiceSelectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .practiceSelectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 22px;
    left: 57%;
    width: 55%;
    transform: translateX(-65%);
  }
}
$breakpoint-tablet: 400px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 22px;
    left: 38%;
    transform: translateX(-50%);
  }
}
$breakpoint-tablet: 360px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 22px;
    left: 41%;
    transform: translateX(-50%);
  }
}
.rightSection {
  display: flex;
  justify-content: center;
  align-self: center;
  & div[id*='userNameSectionGroup'] {
  display: none;
  }
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.messages {
  padding: 0 10px;
  color: $black;
  margin-top: 6px;
}
.flexCenter {
  align-self: center;
}
.hamburgerMenu {
  display: flex;
  flex: 1;
  padding-left: 10px;
  flex-direction: column;
  & a {
    padding: 0;
    padding-left: 10px;
    list-style: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    color: $gray-8;
    font-family: 'Metropolis', sans-serif;
    & [class*='active'] {
      border-left: 2px solid $main-color;
      color: $main-color;
    }
  }
}
.hamburgerContainer {
  position: absolute;
  width: 172px;
  top: 5px;
  border-radius: 5px;
  & [class*='hamburger'] {
    padding-top: unset;
    padding-left: unset;
    padding-bottom: unset;
    margin-top: 2px;
    margin-left: 2px;
  }
  & [class*='active'] {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.language{
  display: none;
}
.internal {
  display: flex;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .internal {
    display: none;
  }
  .language{
    display: flex;
  }
}

