@import '../../styleguide/variables';

.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.vertical {
  display: flex;
  flex-direction: column;
}
.grid {
  display: grid;
}
.providerAddress {
  margin: 0 auto;
  & h6 {
    color: $gray-5;
    font-size: 14px;
    font-weight: 400;
  }
}

.signupContainer {
  align-items: center;
  justify-content: space-between;
  background: top / cover $main-color url(https://assets.gobreeze.com/signup-photo.jpg);
  width: 100vw;
}

.signupFormWrapper {
  height: 100%;
  margin-bottom: 30px;
  & img {
    height: 45px;
    margin: 58px 0 58px;
  }
  & form[id*='signupFields'] {
    width: 412px;
    min-height: 620px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 28px;
    padding-bottom: 10px;
    & [class*='divider'] {
      display: none;
    }
  }
  & [id*='signupForm'] {
    justify-content: flex-start;
    border-radius: 10px;
    margin: 0 auto;
    align-items: center;
    & [class*='heading'] {
      display: none;
    }
    & [class*='passwordPrompt'] > h5 {
      color: $gray-4;
      font-size: 11px;
      font-family: 'Metropolis';
      font-weight: 300;
      line-height: 15px;
      margin: 0;
      width: 300px;
      text-align: center;
    }
    & label {
      color: $gray-7;
    }
    & input {
      font-size: 16px;
    }
    & [class*='formWrapper'] {
      padding: 0;
    }
    & div[class*='leftSideWrapper'] {
      padding: 0 !important;
      margin-left: 0;
    }
  }
  & button[id='passwordeyeIcon'],button[id='repeatPasswordEyeIcon'],button[id='passwordeyeIcon_2'],button[id='repeatPasswordEyeIcon_2']{
    margin: 0;
    position: relative;
    bottom: 9rem;
    left: 11rem;
    &>img{
      width: 18px;
      margin: 0;
    }
  }
  & button[id='repeatPasswordEyeIcon']{
    bottom: 4rem;
    left: 9rem;
  }
  & button[id='passwordeyeIcon_2']{
    bottom: 9rem;
    left: 19.5rem;
  }
  & button[id='repeatPasswordEyeIcon_2']{
    bottom: 4rem;
    left: 17.5rem;
  }
}

.apptDescription {
  justify-content: space-between;
  margin-right: 3px;
  & > div {
    width: 60%;
  }
  & button {
    width: 20%;
  }
}
.myHealthDescription {
  @extend .apptDescription;
  & > div {
    width: 50%;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .apptDescription {
    justify-content: space-between;
    margin-right: 3px;
    & div {
      width: 58%;
    }
    & button {
      width: 41%;
      font-size: 10px;
    }
  }
  .myHealthDescription {
    @extend .apptDescription;
    margin-left: 4%;
    margin-top: 12%
  }
}
.myHealthNav {
  height: 276px;
  width: 68px;
  border: 1px solid $gray-2;
  border-radius: 100px;
  background-color: $white;
  margin-top: 85px;
  position: sticky;
  top: 188px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  & a {
    padding: 16px 16px 0 16px;
    opacity: 1;
    &:active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.myHealthContent {
  padding-top: 36px;
  padding-bottom: 80px;
  & h1 {
    height: 42px;
    color: $gray-3;
    font-size: 42px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 50px;
  }
  & div[class*='emptyBox'] {
    margin: 0;
    margin-top: 14.5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 0 0 50.5px;
    height: 143px;
    & h3 {
      margin-top: 0;
    }
    & a {
      color: $gray-4;
      font-weight: initial;
      font-size: 13px;
      line-height: 16px;
      pointer-events: none;
      cursor: default;
    }
  }
}
.myHealthSideBar {
  padding-right: 111.5px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .myHealthContent {
    padding-top: 36px;
    padding-bottom: 80px;
    margin-left: 4%;
    & h1 {
      height: 42px;
      color: $gray-3;
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -2px;
      line-height: 50px;
    }
    & div[class*='emptyBox'] {
      margin: 0;
      margin-top: 14.5px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 0 0 0 50.5px;
      height: 143px;
      & h3 {
        margin-top: 0;
      }
      & a {
        color: $gray-4;
        font-weight: initial;
        font-size: 13px;
        line-height: 16px;
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .myHealthSideBar {
    // padding-right: 111.5px;
    display: none;
  }
}
.contentCards {
  max-width: 898.5px;
  flex: 1;
  & [class*='emptySection-wrapper'] {
    flex: 1;
    padding-bottom: 71px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .contentCards {
    max-width: 98%;
    flex: 1;
    & [class*='emptySection-wrapper'] {
      flex: 1;
      padding-bottom: 71px;
    }
  }
}
.expansion {
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & h6 {
    color: $gray-8;
    font-size: 13px;
  }
  & > div:last-child {
    text-align: left;
  }
}
.careTeamExpansionProviderInfo {
  padding-left: 0.5rem;
  padding-top: 6px;
  & h4 {
    color: $gray-9;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    font-family: 'Metropolis', sans-serif;
  }
  & h5 {
    margin: 0;
    font-size: 15px;
    color: $gray-5;
    font-weight: 600;
    text-transform: capitalize;
  }
}
.careTeamExpansion {
  @extend .expansion;
  & > div:first-child {
    flex: 60;
  }
  & > div:last-child {
    flex: 40;
  }
}
.lastApptGroup {
  & div:last-child {
    padding-left: 3px;
  }
}
.allergyExpansion {
  @extend .expansion;
  justify-content: flex-start;
  align-items: center;
  & h4 {
    color: $gray-8;
    font-family: 'Metropolis', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  & > div {
    flex: 20;
  }
  & > div:first-child {
    flex: 60;
  }
}
.medicationExpansion {
  @extend .expansion;
  & > div {
    flex: 20;
  }
  & > div:nth-child(2) {
    flex: 40;
  }
  & > button {
    flex: 14;
    height: 30px;
    border-radius: 6px;
    color: $main-color;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
  & h4 {
    color: $gray-8;
    font-family: 'Metropolis', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  &:after {
    content: '<div class=' spacer '></div>';
  }
  & > div:first-child {
    flex: 40;
  }
}
.medicationExpansionTable {
  & > div {
    & > div {
      width: 50%;
    }
    & > div:nth-child(2n + 1) {
      & h6 {
        font-weight: 600;
      }
    }
  }
}
.medicationExpansionEducationLinkButton {
  padding-top: 7px;
}
.allergyReactionNames {
  @extend .expansionLabel;
}
.expansionLabel {
  & h6 {
    font-weight: 600;
  }
}
.loginLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 15px;
  margin & a {
    color: $main-color;
    letter-spacing: 0.1px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer {
  color: $gray-1;
  background-color: $main-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  line-height: 75px;
  padding: 0 30px;
  z-index: 11;
  position: relative;
  width: 100%;
  & div[id='footerVersion']{
    padding: 2px 0;
    margin-left: auto;
    margin-top: 2px;
    & > :first-child{
        color: #fff;
        line-height: normal;
        font-weight: 300;
      }
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .footer {
    color: #ECEFF1!important;
    display: flex!important;
    flex-direction: column;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center!important;
    background-color: #fff;
    line-height: 0px;
    padding: 0;
    gap: 0px;
    width: 100%;
    z-index: 11;
    position: fixed;
    top: 85%;
    height: 74px;
    margin-bottom: 10px;
    & div[id*='Button'] {
      padding-bottom: 10px;
      background-color: white;
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: nowrap
    }
    & div[id*='icons'] {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: 2%;
      margin-right: 2%;
      width: 96%;
      gap: 15px;
      background-color: white;
    }
  }
}


.paymentHistoryReceipt {
  display: flex;
  align-items: center;
  height: 60px;
}
.paymentHistoryPlanDescription {
  align-items: center;
}
.paymentHistoryContainer {
  @extend .modalContentContainer;
  display: unset;
}
.creditCardsContainer {
  margin-bottom: 20px;
  & div[class*='labelContainer'] {
    & span {
      padding-right: 5px;
    }
  }
}
.modalContentContainer {
  background-color: $white;
  border-radius: 0px 0px 10px 10px;
}
.headerContainer {
  background-color: $white;
}
.upcomingOneTimePaymentInfo {
  display: flex;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 1px solid $gray-1;
  border-radius: 6px;
  align-items: center;
  padding: 10px;
  & [id*='clockIcon'] {
    flex: 0.2;
    height: 20px;
  }
  & [id*='upcomingOneTimePaymentDetailsAmount'] {
    flex: 0.4;
    margin-left: 10px;
    & h4 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  & [id*='upcomingOneTimePaymentDetails'] {
    flex: 3;
    & h4 {
      font-size: 16px;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  & [id*='editPaymentPlanButton'] {
    flex: 0.5;
    height: 24px;
    cursor: pointer;
  }
}
.paymentHistoryModalHeader {
  display: flex;
  justify-content: space-between;
  background-color: $gray-1;
  border-radius: 10px 10px 0px 0px;
  height: 22px;
  padding: 5px 15px;
}
.paymentsHistBtns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 30px 22px 20px;
  background-color: $white;
  border-radius: 0 0 10px 10px;
}
.patientBalance {
  @extend .planCompletedOn;
}
.planModalInstallments {
  @extend .planCompletedOn;
}
.planModalCompletedOn {
  @extend .planCompletedOn;
}
.planCompletedOn {
  border-bottom: 1px solid lightgray;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.patientItem {
  display: flex;
  justify-content: space-between;
  color: $gray-5;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
  margin: 8px 41px 0px 40px;
  align-items: center;
  padding-bottom: 8px;
  height: 40px;
}
.patientItemRight {
  @extend .vertical;
  align-items: flex-end;
}
.paymentPlanHeader {
  height: 106px;
  width: 100%;
  background-color: $gray-8;
  border-radius: 10px 10px 0px 0px;
  justify-content: center;
  align-items: baseline;
}
.noOnlinePayments {
  @extend .horizontal;
  justify-content: center;
  margin-top: 25px;
}
.paymentsHist {
  @extend .horizontal;
  & > div > div > div > div:first-child {
    background-color: $highlight;
    height: 42%;
  }
}
.paymentsBtnsNoOnline {
  @extend .horizontal;
  justify-content: center;
  padding: 16px 30px 22px 30px;
  background-color: $white;
  border-radius: 0 0 10px 10px;
}

.makePaymentsAddressContainer {
  margin-left: 13px;
}
.makePaymentsContent {
  margin-top: 20px;
}
.makePaymentsType {
  width: 340px;
  margin-bottom: 20px;
}
.makePaymentsStickyContainer {
  position: sticky;
  top: 40px;
}
.makePaymentsSelect {
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid lightgray;
  height: 40px;
  margin-top: 4px;
}
.successfulPayment {
  border-radius: 10px 10px 0px 0px;
  background-color: $gray-8;
  align-items: center;
}
.makePaymentsCancelBtns {
  @extend .vertical;
  align-items: center;
  background-color: $white;
  border-radius: 0 0 10px 10px;
}
.confirmCancelPaymentsButtons {
  @extend .horizontal;
  flex: 1;
  justify-content: space-evenly;
  background-color: $white;
  border-radius: 0 0 10px 10px;
  margin: 20px 7px;
}
.makePaymentsCenterContent {
  margin-left: 94px;
  width: 340px;
}
.makePaymentsShopCenterContent {
  margin-left: 330px;
}
.makePaymentsRightContent {
  margin-left: 94px;
}

.appointmentPaymentContent {
  flex-wrap: nowrap;
}
.appointmentPaymentAddressContainer {
  @extend .makePaymentsAddressContainer;
}
.appointmentPaymentCenterContent {
  @extend .makePaymentsCenterContent;
}
.appointmentPaymentCenterHeaders {
  @extend .makePaymentsType;
  margin-bottom: 40px;
}
.appointmentPaymentPayment {
  @extend .makePaymentsType;
  & div[id*='paymentsBtnsNoOnline'] {
    width: 600px;
    text-align: center;
    margin-left: -200px;
  }
}
.appointmentPaymentRightContent {
  @extend .makePaymentsRightContent;
}

.addAppointmentProvider {
  @extend .vertical;
  align-items: flex-start;
  flex-basis: 355;
}
.addAppointmentContent {
  margin-top: 45px;
}
.addAppointmentAvailableHours {
  justify-content: flex-end;
}
.requestApptSuccessHeader {
  border-radius: 10px 10px 0px 0px;
  background-color: $gray-8;
  align-items: center;
}
.changeSignupEmailHeader {
  border-radius: 10px;
  background-color: white;
  align-items: center;
}
.changeSignupEmailHeader > div:nth-of-type(2) {
  width:80%
}
.successfulApptRequestBtns {
  background-color: $white;
  border-radius: 0 0 10px 10px;
}
.bannerApptHeader,
.confirmSSNOrDLHeader, .bannerNotificationHeaderModal {
  border-radius: 10px 10px 0px 0px;
  background-color: $gray-8;
  align-items: center;
  padding: 10px 25px;
}
.bannerApptBtns,
.confirmSSNOrDLBtns, .bannerNotificationButtonContainer {
  background-color: $white;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  flex-direction: row;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
}
.selectBusiness{
  padding-bottom: 24px;
}
.selectPracticePractices{
  padding: 12px 0px;
}
// Add Appointment date picker styles
.availableHoursGroup {
  margin-top: 3rem;
  display: flex;
  & > div {
    flex: 1;
  }
  & > div:first-child {
    flex: 3;
  }
  & > div:last-child {
    top: 170px;
    position: sticky;
    height: 100%;
  }
  & div.SingleDatePicker_picker {
    display: contents;
    padding-bottom: 70px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    height: unset;
  }
  & div.DayPicker {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid $gray-2;
    border-radius: 6px 6px 0 0;
  }
  & div.SingleDatePickerInput {
    background-color: transparent;
  }
  & div.DayPicker_transitionContainer {
    height: unset;
  }
  & div[class*='activeCalendarWrapper'] {
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  & div[class*='buttonsWrapper'] {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: unset;
    background-color: $white;
    border: 1px solid $gray-2;
    border-top: none;
    border-radius: 0 0 6px 6px;
    margin-left: 0;
    & button:first-child {
      background-color: transparent;
    }
  }
  & [id*='availableHoursSection'] > [class*='heading'] {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }


}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .availableHoursGroup {
    margin-left: 10%;
    margin-top: 0;

  }
}
.noMessages {
  color: $gray-5;
  font-family: 'Metropolis', sans-serif;
  width: 360px;
  min-height: 80vh;
  text-align: center;
  margin: 0 auto;
  & div {
    display: flex;
    justify-content: center;
  }
  & h1 {
    color: $gray-8;
    font-family: 'Metropolis', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: -1px;
    margin: 0 0 4px 0;
  }
  & h2 {
    height: 32px;
    color: $gray-9;
    font-size: 26px;
    font-family: 'Metropolis', sans-serif;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 5px 0;
    margin: 0;
  }
  & h3 {
    color: $gray-5;
    border-bottom: 1px solid $gray-1;
    font-family: 'Metropolis', sans-serif;
    font-size: 19px;
    font-weight: 300;
    line-height: 23px;
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
  & h4 {
    height: 25px;
    font-size: 14px;
    color: $gray-5;
    font-weight: 300;
    padding: 12px 0;
    margin: 0;
  }
  & h6 {
    height: 36px;
    width: 300px;
    font-weight: 200;
    font-size: 13px;
    line-height: 18px;
  }
  & img {
    box-sizing: border-box;
    height: 168px;
    width: 90px;
    justify-content: center;
    margin: 0 auto;
    padding-top: 80px;
  }
}

.f1 {
  display: flex;
  flex: 1;
}
.rightSection {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-self: center;
  & > a:hover {
    filter: hue-rotate(20deg) brightness(0.35) opacity(0.7);
    cursor: pointer;
  }
}
@media (max-width: $breakpoint-mobile)
{
  .internal {
    display: none;
  }

}

.messagesHeader {
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 36px 14px;
  min-height: 37.48px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .messagesHeader {
    justify-content: space-between;
    align-items: center;
    min-height: 37.48px;
    width: 98%;
    padding: 85px 4px 6px 4px;
   & div[class*='headerIcon']{
    display: none;
  }
  }

}

.newMessagePromptSection {
  display: flex;
  flex-wrap: noWrap;
  width: 364px;
  padding-top: 14px;
  padding-left: 14px;
  & img {
    box-sizing: border-box;
    height: 55px;
    width: 60px;
    justify-content: center;
    margin: 0;
  }
}

.providerSidePanel {
  display: none;
  @extend .newMessagePromptSection;
  width: 344px;
  justify-content: center;
  min-height: 147px;
  align-items: center;
  & h3 {
    height: 20px;
    color: $gray-2;
    font-family: 'Metropolis', sans-serif;
    font-size: 15.5px;
    font-weight: 300;
    line-height: 20px;
    margin: 24px auto;
    letter-spacing: 0.3px;
  }
  & [class*='providerCard'] {
    width: 338px;
  }
}

.newMessagePrompt {
  width: 239px;
  margin-left: 15px;
  padding-right: 42px;
  & h5 {
    line-height: 20px;
  }
}

.firstConversationPrompt {
  margin-top: 84px;
  top: 266px;
  min-height: 477px;
  width: 700px;
  justify-content: center;
  align-items: center;
  & img {
    width: 163.57px;
    height: auto;
    color: $gray-1;
    margin: 0 auto 15px;
  }
}

.nextConversationPrompt {
  margin-top: 84px;
  top: 266px;
  min-height: 477px;
  width: 700px;
  justify-content: center;
  align-items: center;
  & img {
    width: 163.57px;
    height: auto;
    color: $gray-1;
    margin: 0 auto 15px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.nextConversationPrompt {
  margin-top: 84px;
  top: 266px;
  min-height: 350px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

}

.providerMessagePrompt {
  opacity: 1;
}

.providerListContainer {
  align-items: center;
  margin-top: 84px;
  height: 477px;
}

.providerList {
  height: 405px;
  overflow: scroll;
  align-content: flex-start;
  justify-content: space-between;
  padding: 5px 40px 3px;
  width: 620px;
  & > div {
    width: 298px;
  }
  & [class*='readMarker'] {
    display: none;
  }
  & [id='unread'] {
    display: none;
  }
  & [class*='shifted'] {
    transform: translate(0px, 0px);
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.providerList{
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  padding: 5px 14px 5px;
  width: 95%;
}
.providerListContainer {
  margin-top: 84px;
  height: 375px;
}
}
.providerDetails {
  height: 82px;
  justify-content: center;
  margin: 0;
}

.deleteConversationHeader {
  width: 100%;
  height: 174px;
  border-radius: 10px 10px 0 0;
  justify-content: space-evenly;
  padding: 10px 0;
  align-items: center;
  text-align: center;
  background-color: $main-color;
  & h4 {
    color: $white;
  }
  & h6 {
    color: $white;
    padding: 0 20px;
    line-height: 1.3;
  }
}

.patientPortalApps {
  margin-top: 10px;
  justify-content: center;
  width: 600;
  & > div {
    width: 60%;
  }
  & h2 {
    font-size: 22px;
    font-weight: 500;
    font-family: 'Metropolis', sans-serif;
    margin: 10px 0px;
    line-height: 29px;
    color: $gray-8;
  }
  & h4 {
    font-size: 14px;
    color: $gray-7;
    line-height: 19px;
    font-weight: 300;
  }
}
.patientPortalMobileAppGroup {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 9px;
}
.patientPortalMobileAppGroupLanding {
  @extend .patientPortalMobileAppGroup;
  margin-top: 10px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.supportContent{
  margin-left: 5%;
}
}
.supportLinks {
  flex: 1;
  max-width: 183px;
  padding-top: 72px;
  padding-right: 213px;
  & a {
    font-family: 'Metropolis', sans-serif;
    color: $gray-4;
    font-size: 19px;
    text-decoration: none;
    margin-bottom: 10px;
    line-height: 23px;
    &:hover {
      color: $gray-8;
    }
  }
}
.supportAction {
  flex: 1;
  padding-top: 60px;
  & img {
    align-self: flex-start;
  }
  & h4 {
    color: $gray-9;
    font-size: 26px;
    font-family: 'Metropolis', sans-serif;
    font-weight: 600;
    margin-top: 18.5px;
  }
  & h5 {
    color: $gray-9;
    font-size: 16px;
    max-width: 350px;
    font-family: 'Metropolis', sans-serif;
    margin-top: -30px;
    margin-bottom: 31px;
    font-weight: bold;
  }
  & h6 {
    color: $gray-5;
    font-size: 16px;
    max-width: 350px;
    font-family: 'Metropolis', sans-serif;
    margin-top: 15px;
    margin-bottom: 31px;
  }
}

.hidden {
  display: none;
}

.insuranceGroup {
  @extend .identityGroup;
}
.identityGroup {
  align-items: center;
  & > div:last-child h6 {
    color: $gray-2;
    font-family: 'Metropolis';
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    padding-left: 0.5rem;
  }
  & > div:first-child h4 {
    color: $gray-7;
    font-family: 'Metropolis';
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
  }
}
.providerInfoWrapper {
  flex: 1;
  height: 100%;
  position: sticky;
  top: 180px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .providerInfoWrapper {
    display: none;
    justify-content: center;
    flex: 1 1;
    flex-wrap: initial;
    top: 160px;
    background: white;
    & > div{
      & > img{
        display:none;
    }
    }
  }
  .appointmentCheckInIntake{
    display: none;
  }
}
.providerInfo {
  display: flex;
  & > div[id*='providerName'] {
    // height: 28px;

    & h3 {
      font-family: 'Metropolis' !important;
      color: $main-color;
      font-size: 20px;
      padding-top: unset;
    }
    & button[class*='nextStepBtn'] {
      margin: 0;
    }
  }
  & div h3 {
    color: $gray-5;
    font-family: 'Metropolis' !important;
    font-size: 15px;
    font-weight: 500;
    padding-top: unset;
  }
  & div h5 {
    height: 15px;
    color: $gray-7;
    font-family: 'Metropolis' !important;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  & div h6 {
    width: 162px;
    font-family: 'Metropolis' !important;
    color: $gray-7;
    font-size: 12px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .providerInfo {
    display: flex;
    & > div[id*='providerName'] {
      // height: 28px;
      display: none;
      & h3 {
        font-family: 'Metropolis' !important;
        color: $main-color;
        font-size: 18px;
        padding-top: unset;
      }
    }
    & div h3 {
      display: none;
      color: $gray-5;
      font-family: 'Metropolis' !important;
      font-size: 13px;
      font-weight: 500;
      padding-top: unset;
    }
    & div h5 {
      display: none;
      height: 15px;
      color: $gray-7;
      font-family: 'Metropolis' !important;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    & div h6 {
      display: none;
      width: 162px;
      font-family: 'Metropolis' !important;
      color: $gray-7;
      font-size: 12px;
    }
  }
}
.appointmentCheckInScreen {
  & > div:last-of-type {
    flex: 2;
  }
  & h3 {
    color: $gray-9;
    font-family: 'Metropolis';
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    padding-top: 3rem;
  }
  & h2 {
    color: $gray-8;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 500;
    margin: 0;
  }
  & h4 {
    width: 449px !important;
    color: $gray-5;
    font-family: 'Metropolis';
    font-size: 19px;
    padding-top: 1rem;
    font-weight: normal;
    line-height: 24px;
  }
  & h6 {
    color: $gray-4;
    font-family: 'Metropolis';
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;
  }
  & input {
    &:disabled {
      color: $gray-2;
      cursor: not-allowed;
    }
  }
  & [class*='subHeading'] {
    padding-bottom: 0;
    & h3 {
      font-size: 21px;
    }
  }
  & div[id*='emergencyContactInfo'] > h5 {
    color: $gray-4;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: 'Metropolis';
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 49px;
  }
  & form[id*='details'] > div:last-child {
    margin-bottom: 15px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.appointmentCheckInScreen{
  margin-left: 5%;
  & > div:last-of-type {
    flex: none;
    width: 100%;
  }
}
}
.infoCheckInSection {
  padding-top: 1rem;
  & > div:last-child {
    flex: 2;
    padding-left: 1rem;
  }
  & > div:first-child {
    flex: 3;
    padding-right: 5rem;
    border-right: 1px solid $gray-1;
  }
}
.nameInfoCheckInSection {
  @extend .infoCheckInSection;
}
.addressInfoCheckInSection {
  @extend .infoCheckInSection;
}
.detailsInfoCheckInSection {
  @extend .infoCheckInSection;
}
.documentsInfoCheckInSection {
  @extend .infoCheckInSection;
}

.cancelAppointmentModalBody {
  padding: 5px 40px 40px 40px;
}
.cancelAppointmentRadioBtnGroup {
  padding-bottom: 15px;
}
.cancelApptSuccessHeader {
  border-radius: 10px 10px 0px 0px;
  background-color: $gray-8;
  align-items: center;
}

.profileSettingsUserBox {
  position: relative;
  height: 194px;
  width: 160px;
  border: 1px solid $gray-2;
  border-radius: 6px;
  align-items: center;
  & [class*='wrapper'] {
    display: unset;
    margin-top: 16px;
  }
  margin-bottom: 33px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .profileSettingsUserBox {
  display: none;
  }
}
.profileSettingsNav {
  margin-right: 110px;
  position: sticky;
  top: 60px;
  margin-bottom: 20vh;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .profileSettingsNav {
   display:none
  }
}
.profileSettingsRightContent {
  flex: 2;
  max-width: 700px;
  & [id*='demographics'] [id*='address'] {
    & [class*='emergencyContactCity'] {
      width: 204px;
      padding-right: 0;
    }
    & [class*='emergencyContactState'] {
      width: 100px;
      padding-right: 0;
      & div[class*='single'] {
        width: 100px;
      }
    }
  }
}
div[id*='documents'] [id*='identityGroup'] {
  padding-top: 0;
}
div[class*='formWrapper'] {
  padding-bottom: 15px;
}
.expanderGroup {
  flex: 1;
}
.expanderContentGroup {
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 10px;
  :global([class*='Select--single']) {
    width: 100%;
  }
}
.expandedContentRow {
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 $gray-2;
  padding-bottom: 15px;
  padding-top: 31px;
}

.addAppointmentCheckOutHeader {
  justify-content: space-between;
}
.signInLinks {
  justify-content: space-between;
  align-items: center;
}
.availableHoursAppointmentGroup {
  & > div:last-of-type {
    text-align: center;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .availableHoursAppointmentGroup {
    margin-bottom: 15%;
    // margin-top: -90px;
    width: 100%;
    & > div[id*='appt'] {
      margin: 0;
    }

    & > div:last-of-type {
      text-align: center;
    }
  }
}
.formsViewHeadingGroup {
  pointer-events: all;
  & h5 {
    height: 13px;
    width: 42px;
    color: $main-color;
    font-family: 'Metropolis';
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 60px;
  }
  & h2 {
    height: 32px;
    color: $gray-8;
    font-family: 'Metropolis';
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 38px;
  }
}
.guestRegisterHeader, .guestRescheduleAppointmentHeader, .guestCheckinHeader {
  background-image: url('https://assets.gobreeze.com/standalone-signup.jpg');
  padding: 1rem 0 6rem;
  width: 100%;
  background-size: cover;
  background-position: 90px;
  & h3,
  h6 {
    margin: 0 auto;
    color: $white;
    font-weight: 300;
  }
  & img {
    margin-bottom: 30px;
  }
}
.isMobile {
  & [class*='signupFormWrapper'] {
    & form[id*='signupFields'] {
      border-radius: 0;
    }
  }

  & [class*='guestRegisterFormGroupContainer'], [class*='guestRescheduleAppointmentFormGroupContainer'], [class*='guestCheckinFormGroupContainer'] {
    border-radius: 0;
  }
  & [class*='signupContainer'] {
    background: $main-color;
    overflow-x: hidden;
    position: relative;
  }

  & [class*='delegateVerificationFormWrapper'] {
    max-width: 100vw;
    width: unset;
    border-radius: 0;
    margin: auto 0;
  }

  & [class*='delegateEntryBase'] {
    & div button[class*='submitButtonBottom'] {
      width: 320px !important;
    }
  }

  & [class*='delegateVerificationFormWrapper'] {
    max-width: 100vw;
    width: unset;
    border-radius: 0;
    margin: auto 0;
  }

  & [class*='delegateLoginFormWrapper'] {
    border-radius: unset;
    width: 100vw;
    margin: unset;
    padding: 0;
    justify-content: center;
  }
}

.guestRescheduleAppointmentHeader, .guestCheckinHeader {
  padding: 50px 0 120px 0;
  & img{
    width: 65%;
    margin: 50px auto;
  }
}

.guestRegisterFormGroupContainer,.guestRescheduleAppointmentFormGroupContainer,.guestCheckinFormGroupContainer {
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  text-align: left;
  transition: all 0.2s;
  margin: 0 auto;
  margin-top: -80px;
  width: 400px;
  background: $white;
  padding: 30px 0px;
  border-radius: 10px;
  & [id*='guestRescheduleAppointmentDateOfBirthWrapper']:first-child > div {
    justify-content: flex-start;
  }
  & [id*='guestRegisterDateOfBirthWrapper']:first-child>div{
    justify-content: flex-start;
  }
  & [id*='guestRegisterContinueBtn'], [id*='guestRescheduleAppointmentContinueBtn'], [id*='guestCheckinContinueBtn'] {
    width: 340px;
    margin: 20px auto;
    &:not([disabled]) {
      color: $white;
      cursor: pointer;
      background-color: $main-color;
    }
  }
  & [id*='guestRegisterLinkContainer'] {
    // padding-top: 20px;
    margin: 0 auto;
    & a {
      color: $main-color;
      text-decoration: none;
    }
  }
  & [class*='formWrapper'] {
    margin: 0 auto !important;
    padding: 0px 15px !important;
  }
}
.guestPayHeader {
  @extend .guestRegisterHeader;
  & img {
    width: 50%;
    margin: 0 auto 2rem;
  }
}
.guestPayButtonBase {
  width: 340px;
  margin: 20px auto;
  &:not([disabled]) {
    border: 1px solid $main-color;
    color: $main-color;
    cursor: pointer;
    background-color: $white;
    &:hover {
      color: $white;
      background-color: $main-color;
    }
  }
}
.guestPayPrintIconWrapper {
  & > div {
    margin: 0 auto;
  }
}
.guestPayFormGroupContainer {
  @extend .guestRegisterFormGroupContainer, .guestRescheduleAppointmentFormGroupContainer, .guestCheckinFormGroupContainer;
  & [id*='guestPayContinueBtn'] {
    @extend .guestPayButtonBase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  & [id*='guestPayDontHaveCodeBtn'] {
    @extend .guestPayButtonBase;
    margin-bottom: 15px;
  }
  & [id*='guestPayHaveCodeBtn'] {
    margin-bottom: 0;
  }
  & [id*='guestPayLinkContainer'] {
    margin: 0 auto;
    & a {
      color: $main-color;
      text-decoration: none;
    }
  }
  & [class*='formWrapper'] {
    margin: 0 auto !important;
    padding: 0px 15px !important;
    & > div:first-child {
      flex: 3;
      padding-right: 1rem;
    }
  }
}
.guestPayNoCodeFormGroupContainer {
  @extend .guestPayFormGroupContainer;
  & [id*='guestPayNoCodeContinueBtn'] {
    @extend .guestPayButtonBase;
    margin-bottom: 0px;
  }
  & [id*='guestPayHaveCodeBtn'] {
    @extend .guestPayButtonBase;
    margin-bottom: 15px;
  }
  & [id*='guestPayLinkContainer'] {
    margin: 0 auto;
    & a {
      color: $main-color;
      text-decoration: none;
    }
  }
  & [class*='formWrapper'] {
    margin: 0 auto !important;
    padding: 0px 15px !important;
  }
  & [id*='guestPayNoCodeDateOfBirthWrapper']:first-child > div {
    justify-content: flex-start;
  }
}
.guestAlreadyDoneContainer {
  @extend .guestPayFormGroupContainer;
  & [id*='guestNewSearchBtn'] {
    width: 340px;
    margin: 20px auto;
    color: $white;
    cursor: pointer;
    background-color: $main-color;
  }
}
.guestAlreadyDoneContainer {
  & img {
    width: 50%;
    margin: 0 auto;
    padding-right: 2rem;
  }
}
.guestRegisterTermsAndConditionsContainer {
  margin: 0 auto;
  text-align: center;
}
.guestAppointmentSuccessfulContainer {
  margin: -40px calc(540px - 100vw / 2);
  background: $highlight;
  width: 100vw;
  height: 100vh;
}
.guestAppointmentSuccessfulCard {
  background: $white;
  border-radius: 10px;
  margin: 0 auto;
  width: 380px;
  text-align: center;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  & [id*='guestAppointmentSuccessfulCardTop'] {
    background: $gray-1;
    border-radius: 10px 10px 0px 0px;
    height: 120px;
    padding-top: 10px;
    & [id*='appointmentType'] {
      & h6 {
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
    }
    & [id*='appointmentDate'] {
      & h3 {
        margin: 0 auto;
        text-align: center;
        font-size: 26px;
        font-weight: 300;
        color: $gray-7;
      }
    }
    & [id*='appointmentTime'] {
      & h3 {
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        color: $gray-6;
      }
    }
  }
  & [id*='guestAppointmentSuccessfulName'] {
    margin: 35px 0px auto;
    & h6 {
      margin: 0 auto;
      text-align: center;
      font-size: 22px;
      color: $highlight;
      font-weight: 600;
    }
  }
  & [id*='guestAppointmentSuccessfulSpecialty'] {
    margin: 0 auto;
    & h6 {
      margin-bottom: 15px;
      text-align: center;
      font-weight: 300;
      font-size: 16px;
      color: $gray-5;
    }
  }
  & [id*='officeName'] {
    min-height: 20px;
    @extend .providerAddress;
    padding-top: 15px;
    border-top: 1px solid $gray-1;
    width: 300px;
    & h6 {
      margin: 0 auto;
      text-transform: uppercase;
    }
  }
  & [id*='providerAddressLine1'] {
    @extend .providerAddress;
    min-height: 20px;
  }
  & [id*='providerAddressLine2'] {
    @extend .providerAddress;
    min-height: 20px;
  }
  & [id*='providerAddressLine3'] {
    @extend .providerAddress;
    min-height: 20px;
  }
  & [id*='phone'] {
    margin: 0px auto;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-1;
    width: 300px;
    min-height: 20px;
    & h6 {
      margin: 0 auto;
      color: $gray-5;
      font-size: 14px;
      font-weight: 400;
    }
  }
  & [id*='guestPaymentSuccessEndingIn'] {
    margin: 15px 0px;
    & h6 {
      color: $gray-7;
    }
  }
}
.guestGetStartedHeader {
  background: linear-gradient(342deg, rgb(79, 171, 181) 0%, rgb(89, 192, 203) 100%);
  padding: 80px 0px 120px 0px;
}
.guestPaymentReceiptScreen {
  margin: -40px calc(540px - 100vw / 2);
  background: $gray-1;
  width: 100vw;
  height: 100vh;
}
.guestPaymentReceiptContainer {
  border: 1px solid $gray-1;
  border-radius: 12px;
  background: $white;
  margin: 15% auto;
  width: 900px;
  box-shadow: 0 2px 7px 0 rgba(55, 71, 79, 0.4);
}
.guestPaymentReceiptTopContainer {
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin: 0px 30px;
  border-bottom: 2px solid $gray-1;
}
.guestPaymentReceiptTopRightContainer {
  color: black;
  & [id*='guestPaymentReceiptReceipt'] {
    & h3 {
      font-family: 'Metropolis';
      font-size: 32px;
      font-weight: 500;
      text-transform: uppercase;
    }
    align-self: flex-end;
  }
  & [id*='guestPaymentReceiptRef'] {
    & h3 {
      font-family: 'Metropolis';
      font-size: 20px;
      font-weight: 600;
      color: $highlight;
    }
  }
}
.guestPaymentReceiptCenterContainer {
  @extend .guestPaymentReceiptTopContainer;
  height: 250px;
  border-bottom: 2px solid $gray-1;
}
.guestPaymentReceiptBottomContainer {
  @extend .guestPaymentReceiptTopContainer;
  border-bottom: none;
  height: 110px;
  align-self: flex-end;
  & [id*='guestPaymentSuccessEndingIn'] {
    float: right;
    & h6 {
      margin-top: -5px;
    }
  }
}
.guestPaymentReceiptCenterRightContainer {
  background: $gray-1;
  border-radius: 12px;
  padding: 15px;
  width: 360px;
  & [id*='appointmentType'] {
    & h6 {
      margin: 0 auto;
      font-size: 12px;
      font-weight: 600;
      color: $gray-5;
    }
  }
  & [id*='appointmentDate'] {
    & h3 {
      margin: 0 auto;
      font-size: 25px;
      font-weight: 300;
      font-family: 'Metropolis';
      color: $gray-9;
    }
  }
  & [id*='appointmentTime'] {
    & h6 {
      margin: 0 auto;
      font-size: 15px;
      font-weight: 600;
      font-family: 'Metropolis';
      color: $gray-6;
    }
  }
}
.paymentReceiptAddressHeaders {
  & h6 {
    color: $gray-7;
    font-size: 12px;
    padding-left: 20px;
  }
}
.receiptToFrom {
  & h3 {
    color: $main-color;
    font-size: 20px;
    font-family: 'Metropolis';
    font-weight: 600;
  }
}
.guestPaymentReceiptCenterLeftContainer {
  border-radius: 12px;
  padding: 15px;
  line-height: 18px;
  & [id*='officeName'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='providerAddressLine1'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='providerAddressLine2'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='phone'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='guestPaymentReceiptEmail'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='guestPaymentReceiptName'] {
    @extend .paymentReceiptAddressHeaders;
  }
  & [id*='guestPaymentReceiptFrom'] {
    @extend .receiptToFrom;
  }
  & [id*='guestPaymentReceiptTo'] {
    @extend .receiptToFrom;
  }
}
.surveyCardCustomHeadersContainer {
  background: $gray-1;
  margin: 0;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  padding-bottom: 30px;
}
.singleSurveyQuestionContainer {
  margin-top: 10px;
  height: 100%;
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & [class*='svg-inline--fa'] {
    width: 50px;
    padding: 5px 0px 10px 0px;
  }
}
.thankYouFeedbackContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px;
  width: 700px;
  min-height: 400px;
  justify-content: center;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .thankYouFeedbackContainer {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
    min-height: 400px;
    justify-content: center;
  }
}
.surveysSatisfiedSocialNetworks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 80px;
}
.surveysSatisfiedSocialNetworksIcons {
  min-width: 90px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  margin: 30px 10px;
  cursor: pointer;
  border-radius: 8px;
  & a {
    margin: 0 auto;
    height: 58px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  & > div {
    margin: 0 auto;
  }
}
.surveyWhyNotSatisfiedContainer {
  & > div:nth-child(2) {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
  }
  & textarea {
    height: 100px;
    border-radius: 8px;
    padding: 17px;
    color: $gray-8;
    background: gainsboro;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .surveyWhyNotSatisfiedContainer {
    & > div:nth-child(2) {
      width: 100%;
      margin: 0 auto;
      border-radius: 10px;
    }
    & textarea {
      height: 100px;
      border-radius: 8px;
      padding: 4px;
      color: $gray-8;
      background: gainsboro;
    }
  }
}
.emptySurveySelection {
  display: flex;
  border: 1px dashed $gray-2;
  border-radius: 10px;
  width: 338px;
  flex-direction: column;
  text-align: center;
  padding: 0px 15px 15px 15px;
  background: $gray-0;
  height: 180px;
  align-items: center;
  justify-content: center;
}
.appointmentHistoryHeader {
  align-items: baseline;
  & > div:last-of-type {
    width: 200px;
    margin-left: 12px;
    & div.Select-control {
      border-bottom: 0 !important;
    }
  }
}
.modalTopBase {
  background: $gray-8;
  padding: 30px;
  border-radius: 6px 6px 0px 0px;
}
.guestPayErrorModalTop {
  @extend .modalTopBase;
}
.delegateSectionModalTop {
  @extend .modalTopBase;
}
.signUpErrorModalTop {
  @extend .modalTopBase;
}
.cashPayModalTop {
  @extend .modalTopBase;
}
.successConfirmationGroup {
  grid-row: 2;
  & h2 {
    font-size: 33px;
    color: $white;
    font-weight: 410;
    margin-bottom: 5px;
  }
  & [id*='successConfirmationHeader'] {
    flex-direction: column;
  }
  & [id*='successConfirmationMessage'] {
    flex-direction: column;
  }
  & [id*='successConfirmationCheckmark'] {
    height: 57px;
    margin-bottom: 5px;
  }
  & h4 {
    color: $white;
    font-weight: 100;
  }
}
.delegateProfileScreenTop {
  height: 225px;
  padding-top: 30px;
  grid-template-rows: 100px 115px;
}
.delegateTopBase {
  background: url('https://assets.gobreeze.com/BG.svg') no-repeat;
  background-size: cover;
  height: 320px;
  grid-template-rows: 100px 36px auto;
  padding-top: 40px;
  & h4 {
    height: 17px;
    color: $white;
    font-family: 'Metropolis';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 0 auto;
  }
}
.delegateVerificationScreenTop {
  @extend .delegateTopBase;
}
.delegateLoginScreenTop {
  @extend .delegateTopBase;
}
.delegateCreateAccountScreenTop {
  @extend .delegateTopBase;
}
.delegateForgotPasswordScreenTop {
  @extend .delegateTopBase;
}
.delegateEntryBase {
  width: 380px;
  height: 368px;
  border-radius: 10px;
  background-color: $white;
  margin: -80px auto 0 auto;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  padding: 20px;
  & div[class*='title'] {
    margin: 5px 0;
  }
  & div[class*='form-group'] {
    margin-bottom: 0 !important;
    & select[id*='delegateGender'] {
      background-color: red !important;
    }
  }
  & div button[class*='submitButtonBottom'] {
    height: 45px;
    width: 365px !important;
    border-radius: 6px;
    background-color: $main-color;
    color: $white;
    padding: 0 !important;
    margin-top: 15px;
  }
}
.delegateCardBase {
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  margin: 0 300px;
}
.delegateFormBase {
  @extend .delegateEntryBase;
  height: auto;
  width: 380px;
}
.delegateVerificationFormWrapper {
  @extend .delegateEntryBase;
}
.delegateLoginFormWrapper {
  @extend .delegateFormBase;
}
.delegateForgotPasswordFormWrapper {
  @extend .delegateFormBase;
}
.delegateCreateAccountFormWrapper {
  @extend .delegateFormBase;
  height: auto;
  & [class*='help-block'] {
    height: 30px;
    width: 303px;
    color: $gray-4;
    font-family: 'Metropolis';
    font-size: 11px;
    line-height: 15px;
    margin: 25px auto 20px auto;
    text-align: center;
  }
}
.delegateCardContainer {
  @extend .delegateCardBase;
}
.signupSuccessContainer {
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
  justify-content: center;
  display: grid;
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 0px 20px;
  box-shadow: 0 2px 4px 0 $box_shadow_gray;
  max-width: 380px;
  width: 90%;
  background: white;
  & img[id*='whiteLabelLogo'] {
    height: 106px;
    margin: 20px auto;
    border-radius: 10px;
  }
  & button {
    margin: 10px auto;
  }
  & img[id*='signupSuccessCheckmark'] {
    color: $main-color;
    margin: 5px auto;
    height: 70px;
  }
  & img[id*='signupSuccessCheckmarkNoLogo'] {
    color: $main-color;
    margin: 25px auto;
    height: 70px;
  }
  & h4 {
    color: $gray-8;
    margin: 10px 30px;
    font-size: 18px;
  }
  & h5 {
    color: $gray-4;
    margin: 5px auto;
    font-weight: 500;
  }
  & [id*='loginLinkGroup'] {
    margin-bottom: 10px;
  }
  & a[id*='loginLink'] {
    line-height: 38px;
    font-size: 13px;
    margin-bottom: 5px;
  }
}
.signupSuccessInnerContainer {
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 18px;
  justify-content: center;
  display: flex;
  & h6 {
    color: $gray-4;
    font-weight: 500;
    line-height: 1.7;
  }
  & h6[id*='signupUserEmail'] {
    color: $main-color_darker;
    margin-left: 4px;
  }
}
.manageProfilesDelegates {
  padding: 5px 0px;
  & div[id*='manageProfilePracticeExpanderContainer'] {
    box-shadow: 0 1px 4px $lighter_black;
    border: 2px solid $gray-3;
    margin: 10px 0px;
    border-radius: 6px;
    padding-bottom: 5px;
  }
}
.manageDelegatesDelegates {
  @extend .manageProfilesDelegates;
  & div[id*='manageProfilePracticeExpanderContainer'] {
    padding-bottom: 0px;
  }
}
.manageProfilesManagerDemographics {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
}
.manageProfilePracticeExpanderHeaderSection {
  padding: 5px 20px;
  grid-template-columns: 10% 40% 1fr 2%;
  grid-column-gap: 15px;
  & :first-child {
    display: grid;
  }
  & div {
    grid-row: 1;
  }
  & div[id*='expanderOfManageProfilePracticeChevron'] {
    grid-column: 4;
    cursor: pointer;
    color: $gray-7;
  }
}
.manageDelegatePracticeExpanderHeaderSection {
  @extend .manageProfilePracticeExpanderHeaderSection;
  grid-template-columns: 15% 1fr 5%;
}
.manageProfilePracticeExpandedPermissions {
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 0px 30px 60px;
  border-top: 1px solid $gray-4;
  border-bottom: 1px solid $gray-4;
}
.manageProfilePracticeExpanderFooterSection {
  justify-content: end;
  padding: 20px 0px 15px 0px;
}
.mergeDelegateProfileMaybeConfirmationModalButtons {
  background-color: $white;
  justify-content: center;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
}
.disconnectDelegateProfileMaybeConfirmationModalButtons {
  @extend .mergeDelegateProfileMaybeConfirmationModalButtons;
}
.manageProfileWrapper {
  margin-bottom: 10px;
  width: 90%;
}
.manageDelegatesWrapper {
  margin-bottom: 10px;
  width: 415px;
  grid-row: 3;
}
.manageDelegatesButtonsContainer {
  grid-template-columns: 1fr 1fr;
  padding: 20px 5px;
  justify-items: center;
}
.manageDelegatesExpandedPermissions {
  grid-template-columns: 1fr;
  & div[class*='permissionGroup'] {
    padding: 0px 25px;
  }
  & div[class*='parentWrapper'] {
    & span {
      font-weight: 600;
    }
  }
  &:before {
    position: relative;
    top: 0;
    content: '';
    width: 400px;
    border-top: 1px solid $gray-2;
    margin: 0 auto;
  }
}
.manageDelegatesManagerDemographics {
  grid-row-gap: 10px;
}
.profileSettingsContent {
  grid-row: 3;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{}
.manageSingleProfileWrapper {
  margin-bottom: 50px;
}
.surveyCardTimeWrapper {
  display: grid;
  justify-content: center;
  grid-column-gap: 5px;
}
.telehealthContent {
  max-width: 750px;
  margin-left: 85px;
}
.telehealthButtons {
  margin-top: 20px;
}
.navbarContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 20px;
  height: 88px;
  width: calc(100% - 80px);
  box-sizing: border-box;
  top: 72px;
  left: 80px;
  background-color: $white;
  z-index: 7;
  border-bottom: 1px solid #D9D9D9;
  position: sticky;
}
$breakpoint-tablet:1648px;
@media (max-width: $breakpoint-tablet)
{
   .navbarContainer {
    height: 97px;
  }
}
.secondNavbar {
  right: 0px;
  display: flex;
}
.sidebar{
  -webkit-box-shadow: 1px -1px 4px 0px #E8E9E9;
  -moz-box-shadow: 1px -1px 4px 0px #E8E9E9;
  box-shadow: 1px -1px 4px 0px #E8E9E9;
  width: 80px;
  top: 82px;
  background-color: white;
  position: fixed;
  z-index: 8;
  height: calc(100vh - 72px);
  box-sizing: border-box;
}
.quickActionsContainer {
  flex-wrap: nowrap !important;
  gap: 10px;
  padding: 24px 0 16px 0;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .sidebar{
    overflow-y: auto;
    width: 61px;
  }
  .quickActionsContainer {
    flex-wrap: nowrap !important;
    gap: 7px;
    padding: 26px 0 16px 0;
  }
  .navbarContainer {
    height: 85px;
    padding: 0 7px;
    width: 100%;
  }
  .navbarContainer {
    height: 85px;
    width: 100%;
  }
}
$breakpoint-mobile:500px;
@media (max-height: $breakpoint-mobile)
{
  .sidebar{
    overflow-y: auto;
    width: 61px;
    margin-bottom: 5%;
  }
}
.titleContainer{
  padding-top: 24px;
  padding-bottom: 16px;
  gap: 10px;
}
.practiceSelect{
  border: 1px solid #e8e9e9;
  border-radius: 36px;
  padding-right: 22px;
  height: 36px;
  display: flex;
  justify-content: center;
  width: 468px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .practiceSelect {
    display: flex;
    padding-right: 7px;
    margin-left: 7px;
  }
}
$breakpoint-mobile: 367px;
@media (max-width: $breakpoint-mobile)
{
  .practiceSelect {
    width: 90%;
    & button[id='practiceSelectName']{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }
}
$breakpoint-mobile:500px;
@media (max-height: $breakpoint-mobile)
{
  .practiceSelect{
    border: 1px solid #e8e9e9;
    border-radius: 36px;
    padding-right: 22px;
    height: 36px;
    margin-right: 60px;
    display: flex;
    justify-content: center;
    width: 300px;
  }
}
$breakpoint-tablet:500px;
@media (max-height: $breakpoint-tablet)
{
  .practiceSelect{
    border: 1px solid #e8e9e9;
    border-radius: 36px;
    padding-right: 22px;
    height: 36px;
    display: flex;
    justify-content: center;
    width: 300px;
  }
}
.checkInQRCodeModal{
  padding: 24px 12px;
  align-items: center;
}

$breakpoint-tablet: 400px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelect {
    border: 1px solid #e8e9e9;
    border-radius: 36px;
    padding-right: 22px;
    height: 30px;
    display: flex;
    justify-content: center;
    width: 200px;
  }
}
$breakpoint-tablet: 380px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelect {
    border: 1px solid #e8e9e9;
    border-radius: 36px;
    padding-right: 22px;
    height: 30px;
    display: flex;
    justify-content: center;
    width: 170px;
  }
}
$breakpoint-tablet: 360px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelect {
    border: 1px solid #e8e9e9;
    border-radius: 36px;
    padding-right: 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    width: 138px;
  }
}

.nextAppointmentAndLastVisitWrapper, .historyAndCareTeamWrapper{
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.historyAndCareTeamWrapper{
  padding-top: 20px;
  min-height: 229px;
}
.nextAppointment, .lastVisit, .history, .myCareTeam{
  flex: 1;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(16, 30, 115, 0.12);
  border-radius: 8px;
  gap: 12px;
  padding-bottom: 16px;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .nextAppointment, .lastVisit, .history, .myCareTeam{
    flex: auto;
  }
}
.noLastVisit, .noAppointmentHistory{
  justify-content: center;
  align-items: center;
  flex: 1;
}
.lastVisitTitleWrapper, .nextAppointmentTitleWrapper, .historyTitleWrapper, .myCareTeamTitleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #E8E9E9;
}
.nextAppointmentTabs{
  display: flex;
}

.nextAppointmentDateWrapper, .nextAppointmentTimeWrapper,.nextAppointmentVisitTypeWrapper, .nextAppointmentProviderWrapper, .nextAppointmentLocationWrapper,
.lastVisitDateWrapper, .lastVisitVisitTypeWrapper, .lastVisitProviderWrapper, .lastVisitCurrentBalanceWrapper, .lastVisitDueDateWrapper {
  padding: 0px 26px;
}
.nextAppointmentDateWrapper, .lastVisitDateWrapper{
  padding-top: 8px;
}
 .lastVisitBtnWrapper{
  padding: 0px 26px;
  gap: 10px;
  margin-top: auto;
  & button[id*='patientStatement']{
    height: 40px;
    margin: 0;
  }
}
.nextAppointmentBtnWrapper{
  gap: 26px;
  margin-top: auto;
  padding-left:20px;
}
.noNextAppointmentWrapper{
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1;
}
.nextAppointmentshowTodaywrapper{
  padding: 0px 20px 0px 26px;
}
.nextAppointmentshowUpcomingwrapper{
  display: none;
  padding: 0px 20px 0px 26px;

}

.nextAppointmentEmptywrapper{
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 12px;

}
.navigatebutton{
  justify-content: flex-end;
}
.nextAppointmentEmptyBtnWrapper{
    margin-right: auto;
    margin-top: 5%;
}
.historyBtnWrapper{
  display: flex;
  gap: 10px;
  padding: 0px 12px;

}
.historyAppointment{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E8E9E9;
  padding: 16px 12px;
}
.historyAppointmentLeft{
  align-items: center;
}
.historyAppointmentLeftDetails{
  font-size: 10px;
}
.historyAppointmentImage{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}
.historyAppointmentRight{
  align-items: flex-end;
}
.myCareTeamProvidersWrapper{
  padding: 12px;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  flex: 1;
}
.myCareTeamProvider{
  justify-content: center;
  align-items: center;
  flex: 45%;
  padding: 16px 0px;
  border: 1px solid #EBF8F9;
  box-shadow: inset 0px -1px 0px #E8E9E9;
  border-radius: 10px;
  gap: 4px
}
.noCareTeamProviders{
  justify-content: center;
  align-items: center;
  flex: 1;
}
.careTeamBtnWrapper{
  justify-content: center;
  gap: 10px;
  padding-top: 16px;
}

.appointmentHistoryTab{
  display: grid;
  height: 100%
}
.paymentHistoryTab, .visitHistoryTab{
  display: none;
  height: 100%
}
.twofaDashboardModalText{
  background-color: $gray-8;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
}
.twofaDashboardModalBtns{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $white;
  gap: 24px;
  padding-block: 20px;
}
.resourceNotAvailableText{
  padding-block: 16px;
  background-color: $gray-8;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.completeRegistrationBtnContainer, .resourceNotAvailableBtnContainer{
  @extend .twofaDashboardModalBtns;
  padding-block: 16px;
}
.resendOtpContainer{
  margin-top: 20px;
  justify-content: center;
  align-items: baseline;
  gap: 6px;
}

@media only screen and (max-height: 700px) {
  .availableHoursGroup {
    & > div:last-child {
      top: 40px;
    }
  }
}
[class*='showIndex'] {
  gap: 12px;
  margin-left: -20px;
}

@media screen and (max-width: 460px) {
  .guestRescheduleAppointmentFormGroupContainer{
    & div[id='guestRescheduleAppointmentFormGroup'] :last-child{
        div {
          div{
            margin-left: 0 !important;
          }
        }
    }
  }
  .guestRegisterFormGroupContainer, .guestRescheduleAppointmentFormGroupContainer, .guestCheckinFormGroupContainer{
       width: 360px;
      & form[id*='guestRegister'] {
        width: 312px !important;
        padding-left: 0px;
      }
    }
    .guestRegisterFormGroupContainer, .guestCheckinFormGroupContainer{
      & [id*='guestRegisterContinueBtn'], [id*='guestCheckinContinueBtn'] {
        width: 312px;
        margin: 0px auto !important;
      }
    }
    div[id='guestRescheduleAppointmentTermsAndConditionsContainer'] {
      label {
        div {
          input {
            margin-left: 0 !important;
            margin-right: 12px;
          }
        }
      }
    }
    .guestRegisterTermsAndConditionsContainer {
      text-align: left;
        & input[id='guestRegisterTermsAndConditions']{
          margin-left: 0 !important;
          margin-right: 12px;
        }
    }
  .guestPayNoCodeFormGroupContainer{
    & [id*='guestPayNoCodeContinueBtn'] {
      width: 310px !important;
    }
    & [id*='guestPayHaveCodeBtn'] {
      width: 310px !important;
    }
  }
  .makePaymentsRightContent {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .makePaymentsCenterContent {
    margin: 0 auto;
    margin-top: 15px;
  }
  .makePaymentsContent {
    margin-top: 20px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 360px) {
  .guestPayNoCodeFormGroupContainer{
    & [id*='guestPayNoCodeContinueBtn'] {
      width: 210px !important;
    }
    & [id*='guestPayHaveCodeBtn'] {
      width: 210px !important;
    }
  }
}
  @media (min-width: 768px) and (max-width: 1024px){
    .makePaymentsRightContent {
      margin-left: 30%;
      margin-bottom: 20px;
    }
    .makePaymentsCenterContent {
      margin-left: 30px;
      margin-top: 15px;
    }
    .makePaymentsContent {
      margin-top: 20px;
      margin-left: 30px;
    }

  }
  .language{
    display: none;
  }
  .internal {
    display: flex;
  }
  .DropDownInNav{
    display: none;
  }
  $breakpoint-mobile: 567px;
  @media (max-width: $breakpoint-mobile)
  {
    .internal {
      display: none;
    }
    .language{
      display: flex;
    }
    .DropDownInNav{
      display: flex;
    }
     .navSignOut{
        font-size: 10px;
      }
  }
  .activityLogHeader{
    & h3{
    font-family: "Metropolis", sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: #455a64;
    margin: 0;
    line-height: 38px;
    }
  }
  $breakpoint-mobile: 567px;
  @media (max-width: $breakpoint-mobile)
  {
    .activityLogHeader{
      margin-top: 20%;
      font-size: 22px;
    }
  }

@media screen and (max-width:576px){
  div[id*='appointmentPaymentContent'] {
    flex-direction: column;
    div[class*='avatar'] {
      display: none;
    }
  }
  div[id*='appointmentPaymentAddressContainer'] {
    display: none;
  }
  .guestPayHeader {
    text-align: center;
    @extend .guestRegisterHeader;
    & img {
      width: 70%;
    }
  }
}
