@import '../../styleguide/variables';

.btn {
  text-transform: uppercase;
  height: 40px;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  background-image: none;
  border-radius: 6px;
  border: none;
  --webkit-font-smoothing: auto;
  cursor: pointer;
}
.hidden {
  display: none;
}
.nav {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
}
.btnSignIn {
  @extend .primary;
  margin: 10px 0 10px 0 !important;
  &:hover {
    background-color: #43bdc9;
  }
}
.btnSignUpEmailUpdate {
  @extend .primary;
  margin: 10px 0 10px 0 !important;
  width: 80% !important;
  &:hover {
    background-color: #43bdc9;
  }
}
.btnCreateAccount {
  margin: 5px 0;
}
.btnAddAppointment {
  @extend .secondary;
  max-width: 200px;
  height: 20px;
  display: flex !important;
  flex: 1;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .btnAddAppointment {
    max-width: 100px;
    height: 15px;
    margin-right: 3%;
    font-size: 10px;
    display: flex !important;
    flex: 1;
  }
}
.bannerAddAppt {
  color: #5bc5d0;
  box-shadow: 1px 1px 4px rgb(31 155 222 / 40%);
  border: 1px solid #5bc5d0;
  width: 100%;
  display: block;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
  outline: none;
  max-width: 200px;
  height: 20px;
  flex: 1 1;
  box-sizing: content-box;
  text-align: center;
}
.bannerBtn, .bannerNotificationCloseButton {
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Metropolis', sans-serif;
  max-width: 140px;
  height: 43px;
  background-color: #43bdc9;
  color: white;
}
.bannerBtn2 {
  @extend .bannerBtn;
}
.confirmSSNOrDLBtnBack {
  @extend .bannerBtn;
  font-weight: 500;
  background-color: #5bd064;
}
.confirmSSNOrDLBtnProceed {
  @extend .confirmSSNOrDLBtnBack;
}
.intelligentSchedulerContinue {
  @extend .bannerBtn;
}
.intelligentSchedulerContinue.disabled {
  @extend .bannerBtn;
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  color: #cfd8dc;
  outline: none;
  pointer-events: none;
}
.btnCheckInAtOfficeAppointment {
  @extend .secondary;
}
.btnAppointmentVisitSummary {
  height: 13px;
  color: #78909c;
  font-size: 11px;
  text-decoration: none;
  text-align: right;
}
.btnCreateVisitSummary {
  @extend .secondary;
  top: 5rem;
  position: relative;
}
.btnCheckOutNowAppointment {
  @extend .secondary;
}
.startVideoAppt {
  @extend .secondary;
  transition: 0.2s;
  &:hover {
    background-color: #ffffff;
  }
  &[class*='disabled'] {
    @extend .disabled;
  }
}
.btnCheckInNowAppointment {
  @extend .green;
  transition: 0.2s;
  &:hover {
    background-color: #47ca51;
  }
}
.btnCheckInAtOfficeAppointmentDisabled {
  @extend .disabled;
}
.btnCreateNewAccount {
  @extend .secondary;
}
.loadMoreAppointmentsButton {
  @extend .loadMoreBtn;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .loadMoreAppointmentsButton {
      width: 170px;
  }
}
.btnViewMedicalRecord {
  @extend .secondary;
  border-radius: 6px;
}
.exportCreateVisitSummary , .submitCreateVisitSummary, .viewCreateVisitSummary, .downloadCreateVisitSummary {
  @extend .primary;
  height: 40px !important;
  width: 100%
}
// #region Login
.forgotPassword {
  color: #5bc5d0;
  font-size: 11px;
  font-weight: 400;
  align-self: left;
  line-height: 13px;
  background: none;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
  &:hover {
    text-decoration: underline;
  }
}
/////////////////////////////
////// Forgot Password ////////
/////////////////////////////
.recoverPasswordBtn {
  border-radius: 6px;
  background-color: #5bc5d0;
  margin-top: 17px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.5px;
  &[class*='disabled'] {
    background-color: #cfd8dc;
    margin: 25px 0 15px 0;
    pointer-events: none;
    color: white;
  }
}
.signInBtn {
  color: #5bc5d0;
  text-transform: initial;
  font-size: 11px;
  font-weight: 400;
  padding: 25px;
  line-height: 5px;
  background: none;
  &:hover {
    text-decoration: underline;
  }
}
.selectPracticeBtnWrapper {
  padding: 24px;
}
.selectPracticeContinueBtn {
  @extend .primary;
  background-color: $highlight;
  width: 100%;
  height: 48px;
  padding: 12px 24px;
}
.selectPracticeContinueBtn:disabled {
  pointer-events: none;
}
/////////////////////////////
////// Create New Password ////////
/////////////////////////////
.saveNewPasswordBtn {
  background-color: #5bd064;
  margin: 10px 0 15px 0;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.5px;
  width: 100%;
  height: 40px !important;
  padding: 8px;
  &[class*='disabled'] {
    background-color: #cfd8dc;
    margin: 25px 0 15px 0;
    pointer-events: none;
    color: #fff;
  }
}
// Payments
.termsAndConditions {
  margin-top: 0 !important;
  font-size: 15px;
  color: #51bdca;
  font-weight: 500;
  font-family: 'Metropolis';
  text-transform: unset;
  padding-left: 4px;
}
.patientStatement {
  @extend .secondary;
  width: 193px !important;
  height: 48px;
  border-radius: 6px;
  box-shadow: none;
  margin: 10px;
}
.paymentHistoryBack {
  @extend .primary;
  width: 221px;
  border-radius: 6px;
  margin: 10px;
  height: 47px;
  font-weight: 400;
  outline: none;
}
// Payments Modal
.editPaymentPlan {
  @extend .secondary;
  width: 184px !important;
  height: 48px;
  box-shadow: none;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px !important;
}
.paymentOptions {
  @extend .schedulePayment;
  height: 48px;
  background-color: #5bd064;
  margin-left: 9px;
  &:hover {
    background-color: #3dc747;
  }
}
.alertPractice {
  @extend .payNow;
  background-color: #5bd064;
  height: 47px;
  margin-left: 10px;
  width: 230px;
  &:hover {
    background-color: #3dc747;
  }
}
.patientStatementNoOnline {
  @extend .secondary;
  width: 230px;
  height: 48px;
  border-radius: 6px;
  box-shadow: none;
  margin-right: 10px;
}

//#region Make Payments ///////////////
///////////////////////////////////////
/////// Make Payments /////////////////
///////////////////////////////////////
.makePaymentsCancelPlan {
  @extend .makePaymentsCancelPayment;
  margin-top: 0;
}
.successfulPaymentButtonWorkflow {
  @extend .successfulPaymentButton;
  margin-left: 20px;
}
.resendConfirmationEmailButton {
  @extend .primary;
  width: 87%;
  height: 45px;
  margin: 13px auto;
}
.goBackToApptsBtn {
  @extend .green;
  line-height: 48px;
  width: 87% !important;
  text-transform: uppercase;
  &:hover {
    background-color: #4ccc56;
  }
  margin: 13px auto;
  font-weight: 300;
}
.makePaymentsPayLater {
  @extend .green;
  width: 87% !important;
  text-transform: uppercase;
  &:hover {
    background-color: #4ccc56;
  }
  margin: 20px 0px;
}
.makePaymentsConfirmCancel {
  @extend .primary;
  width: 135px;
  height: 40px;
  text-transform: uppercase;
  padding: 0;
}
.makePaymentsConfirmNo {
  @extend .secondary;
  height: 40px;
  width: 135px !important;
  text-transform: uppercase;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.makePaymentsBack {
  @extend .secondary;
  margin: 0px 0px 20px 0px;
  height: 50px;
  width: 87% !important;
  text-transform: uppercase;
  box-shadow: none;
  padding-top: 10px;
}
.editPaymentPlanBack {
  height: 48px;
  text-align: center;
  @extend .secondary;
  box-sizing: border-box;
  padding-top: 12px !important;
  box-shadow: none;
  margin-top: 20px;
}
//#endregion //////////////////////////

// #region No Messages ////////////////
///////////////////////////////////////
///////////// No Messages /////////////
///////////////////////////////////////
.createNewMessageBtn {
  height: 48px;
  width: 350px;
  border-radius: 6px;
  background-color: #5bc5d0;
  cursor: pointer;
  color: #fff;
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 48px;
  margin: 20px auto;
}
// #endregion /////////////////////////

// #region Messages ///////////////////
///////////////////////////////////////
/////////////// Messages //////////////
///////////////////////////////////////
.newMessageCancelBtn {
  display: none;
  color: #5ec4cf;
  height: 38px;
  line-height: 38px;
  width: 163px;
  border: 1px solid #5ec4cf;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
}

.deleteMessageBtn {
  color: #b0bec5;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: 155px;
  text-align: right;
  height: 15px;
}

.markReadBtn {
  color: #b0bec5;
  text-decoration: none;
  text-transform: initial;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: 115px;
  text-align: right;
  height: 15px;
  margin-bottom: 11px;
}

.markUnreadBtn {
  color: #b0bec5;
  text-decoration: none;
  text-transform: initial;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: 125px;
  text-align: right;
  height: 15px;
  margin-bottom: 11px;
}

.newMessageBtn {
  color: #fff;
  height: 38px;
  line-height: 38px;
  width: 163px;
  border: 1px solid #5bc5d0;
  background-color: #5bc5d0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  &:hover {
    background-color: #4cc0cc;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.newMessageBtn {
margin-left: 55%;
}
}
.historyProviderBtn{
  color: #fff;
  height: 38px;
  line-height: 38px;
  width: 163px;
  border: 1px solid #5bc5d0;
  background-color: #5bc5d0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  &:hover {
    background-color: #4cc0cc;
  }
}
.retrySendingMessagebtn {
  align-self: center;
  color: #e22b41;
  padding: 5px;
  margin-right: 15px;
  line-height: 11px;
  font-size: 10px;
  height: 22px;
  border: 1px solid #e22b41;
}

.confirmDeleteBtn {
  color: #fff;
  width: 87%;
  height: 50px;
  line-height: 50px;
  margin: 20px auto;
  text-transform: uppercase;
  background-color: #e22b41;
}

.cancelDeleteBtn {
  color: #5bc5d0;
  width: 87%;
  height: 47px;
  line-height: 47px;
  border: 1px solid #5bc5d0;
  margin: 0 auto 20px;
  text-transform: uppercase;
  background-color: #fff;
  padding-top: 1px;
}
// #endregion /////////////////////////

// Appointments Overlay
.checkInOverlayActiveBtn {
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  margin: 5px 27px 10px 27px;
  text-decoration: none;
  background-color: #5bc5d0;
  padding: 13px 0;
  border-radius: 6px;
  width: 288px;
  font-weight: 400;
  letter-spacing: 0.7px;
  font-size: 13px;
  color: #fff;
  text-transform: none;
}
.checkInOverlayBtn {
  border: 1px solid #5bc5d0;
  margin: 5px 27px 10px 27px;
  text-decoration: none;
  padding: 13px 0;
  border-radius: 6px;
  width: 288px;
  font-weight: 400;
  letter-spacing: 0.7px;
  font-size: 13px;
  color: #5bc5d0;
  text-transform: none;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .checkInOverlayActiveBtn {
    box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
    margin: 5px 27px 10px 27px;
    text-decoration: none;
    background-color: #5bc5d0;
    padding: 13px 0;
    border-radius: 6px;
    width: auto;
    font-weight: 400;
    letter-spacing: 0.7px;
    font-size: 13px;
    color: #fff;
    text-transform: none;
  }
  .checkInOverlayBtn {
    border: 1px solid #5bc5d0;
    margin: 5px 27px 10px 27px;
    text-decoration: none;
    padding: 13px 0;
    border-radius: 6px;
    width: auto;
    font-weight: 400;
    letter-spacing: 0.7px;
    font-size: 13px;
    color: #5bc5d0;
    text-transform: none;
  }
}
// #region Add Appointment ////////////
///////////////////////////////////////
/////////// Add Appointment ///////////
///////////////////////////////////////
.addAppointmentAvailableHours {
  @extend .disabled;
  width: 340px;
  height: 48px;
  margin-right: 23px;
  margin-top: 8px;
  background-color: #cfd8dc;
  color: #fff;
}
.appointmentPaymentPayLater {
  @extend .secondary;
  width: 100%;
  height: 43px;
  box-shadow: none !important;
  margin-top: 20px;
  font-weight: 400;
}
.availableHoursDatePicker {
  height: 13px;
  color: #5bc5d0;
  font-family: 'Metropolis';
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-transform: capitalize;
}
.btnScheduleLater {
  @extend .secondary;
  width: 210px !important;
  margin: 0;
  margin-left: auto;
  margin-top: -10px;
}
.noAvailableHoursButton {
  font-size: 14px;
  font-family: 'Metropolis';
  font-weight: 400;
  text-align: center;
  color: #5bc5d0;
  text-transform: capitalize;
  padding: 0 15px;
}
// #endregion /////////////////////////

// #region Add Appointment ////////////
///////////////////////////////////////
////////// AddAppointment ////////////
///////////////////////////////////////
.btnRequestAppointment {
  @extend .green;
  &[class*='disabled'] {
    background-color: #cfd8dc !important;
    margin-bottom: 0;
    padding: unset;
    display: flex;
    justify-content: center;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
}
// #endregion /////////////////////////

// #region appointment Payment ////////
///////////////////////////////////////
////////// Appointment Payment ////////
///////////////////////////////////////
.appointmentPaymentCheckBalance {
  @extend .makePaymentsCheckBalance;
}
// #endregion /////////////////////////

// #region Signup /////////////////////
///////////////////////////////////////
/////////////// Signup ////////////////
///////////////////////////////////////
.patientSignupBtn {
  @extend .primary;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  width: 340px;
  height: 40px !important;
  line-height: 40px;
  border-radius: 6px;
  background-color: #5bc5d0;
  margin: 30px auto !important;
  padding: 0px !important;
  &[class*='disabled'] {
    width: 340px;
    background-color: #cfd8dc !important;
    color: #fff !important;
    pointer-events: none;
  }
}

.patientSignupBtnDark {
  @extend .patientSignupBtn;
  background-color: #455a64;
}
.incorrectEmailBtn {
  @extend .btn;
  text-transform: none;
  font-weight: 400;
  color: blue;
  text-decoration: underline;
}
.resendConfirmationEmail {
  @extend .incorrectEmailBtn;
  margin-bottom: 18px !important;
}
// #endregion /////////////////////////

.nextStepBtn {
  @extend .primary;
  height: 38px !important;
  width: 147px !important;
  margin-top: 2rem !important;
  &[class*='disabled'] {
    background-color: #cfd8dc !important;
    color: #fff;
  }
}

// #region Profile Settings ////////////
///////////////////////////////////////
/////// ProfileSettings Screen /////////
///////////////////////////////////////
.profileSettingsSignOut {
  text-transform: none;
  color: #607d8b;
  font-family: 'Metropolis';
  font-size: 11px;
  font-weight: 600;
  margin: 0;
  margin-top: 14px;
  line-height: 13px;
  &:hover {
    color: #e22b41;
  }
}
.profileSettingsUpdatePassword {
  @extend .profileSettingsUpdateEmail;
}
.newCreditCardAdd {
  font-weight: 500;
  line-height: 13px;
  @extend .green;
  margin-top: 6px;
  width: 105px;
  height: 35px;
  font-size: 12px;
  background-color: #5bd064;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Metropolis';
  transition: 0.2s;
  display: block;
  &[class*='disabled'] {
    background-color: #cfd8dc !important;
    color: #fff;
    cursor: not-allowed !important;
    margin-bottom: 0;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.newCreditCardAdd{
  margin-bottom: 150px;
}
}
// #endregion /////////////////////////
// cancel appointments modal
.cancelAppointmentProceedPaymentButton {
  padding: 0 5px;
  color: #cfd8dc;
  border: 1px solid #cfd8dc;
  pointer-events: none;
  align-self: flex-end;
  width: 196px;
  font-weight: 400;
  font-size: 14px;
  margin: 15px 0 30px 0;
  float: right;
  height: 46px;
  line-height: 46px;
}
.cancelAppointmentCancelButton {
  color: #cfd8dc;
  width: 198px;
  height: 46px;
  line-height: 46px;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  margin: 20px 0px;
  float: right;
  pointer-events: none;
  font-weight: 400;
}
.loginLanguageDropdown {
  color: #5bc5d0;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 20px;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  &:after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    left: 0.15em;
    position: relative;
    width: 0.35em;
    top: -3px;
    margin-left: 5px;
    margin-right: 5px;
    transform: rotate(135deg);
    background-color: transparent;
  }
}
.emergencyContactAdd {
  @extend .creditCardDetails;
  border: 1px solid #5bc5d0;
  padding: 0 5px;
  margin-top: -10px;
  width: 70px;
  height: 25px;
  margin-right: 0;
}
.removeEmergencyContact {
  @extend .deleteCreditCard;
}
.careTeamActionView {
  @extend .careTeamActionDownload;
}
.surveyGuestNoThankYouButton {
  @extend .surveyNoThankYouButton;
}
.surveysContinueCheckoutButton {
  @extend .surveyNoThankYouButton;
}
.submitSurveyButton {
  color: #fff;
  background: #5bc5d0;
  width: 300px;
  font-weight: 300;
  &:hover {
    color: #5bc5d0;
    background: white;
    border: 1px solid #5bc5d0;
    border-radius: 8px;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
.submitSurveyButton{
margin-bottom: 10%;

}
}
.surveysTopLeftHeader {
  color: #5bc5d0;
  font-size: 16px;
  font-family: 'Metropolis';
  font-weight: 400px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  padding: 0;
}

.polling {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://assets.gobreeze.com/icn-spinner-base.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 35px;
  width: 35px;
  & > img[id*='spinnerLoader'] {
    height: 21px;
    width: 21px;
    animation: spin 2s linear infinite;
    margin-left: 7px;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.delegateLink {
  color: gray;
  cursor: default;
  font-size: 8px;
  margin-top: 7rem;
  margin-bottom: -8rem;
  &[class*='disabled'] {
    background: transparent !important;
    border: none !important;
    color: gray !important;
    cursor: unset !important;
  }
}
.cashPayModalButton {
  @extend .modalButtonBase;
}
.guestPayErrorModalButton {
  @extend .modalButtonBase;
}
.delegateSectionErrorModalButton {
  @extend .modalButtonBase;
}
.delegateSectionModalButton {
  @extend .modalButtonBase;
}
.confirmationInvalidResendButton {
  @extend .primary;
  margin-top: 25px;
  width: 257px;
  height: 48px;
  font-weight: 500;
  line-height: 16px;
}
.registerErrorModalButton {
  @extend .primary;
  margin: 15px auto;
  width: 286px;
  justify-content: center;
  height: 45px;
}
.delegateLoginToggle {
  background-color: #5bc5d0;
  margin: 50px auto 0 auto;
  color: white !important;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-family: 'Metropolis';
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-align: center;
  height: 30px;
  width: 235.8px;
  text-transform: unset;
}
.delegateeName {
  color: #5bc5d0 !important;
  font-weight: 600 !important;
}
.delegateName {
  color: #5bc5d0 !important;
  font-weight: 600 !important;
}
.delegateeRelationDOB {
  color: #78909c !important;
  font-size: 8px !important;
}
.delegateRelationDOB {
  color: #78909c !important;
  font-size: 8px !important;
}
.guestPayContinueBtn {
  margin-bottom: 15px;
}
.creditCardDeleteErrorModalButton {
  @extend .primary;
  margin: 15px auto;
  width: 286px;
  justify-content: center;
  height: 45px;
}
.manageDelegatesPracticeExpendedButtonUpdate {
  @extend .manageDelegatesPracticeExpendedButtonMerge;
  height: 40px !important;
  &[class*='disabled'] {
    color: #fff;
    border: 1px solid #cfd8dc;
    background-color: #cfd8dc;
    cursor: not-allowed !important;
  }
}
.manageProfilePracticeExpandedMergePopupApplyButton {
  margin-top: 25px;
  height: 40px;
}

.disconnectDelegateProfileConfirmationModalNo {
  @extend .mergeDelegateProfileConfirmationModalNo;
}
.disconnectDelegateProfileConfirmationModalYes {
  @extend .mergeDelegateProfileConfirmationModalYes;
}
.logoutCountdownModalContinueBtn {
  @extend .primary;
}
.logoutCountdownModalLogoutBtn {
  @extend .primary;
  background-color: #e22b41 !important;
  &:hover {
    background-color: #e22b41 !important;
  }
}
.apolloErrorBtn {
  @extend .primary;
}

.primary {
  color: #fff;
  background-color: #5bc5d0;
  &:hover {
    background-color: #43bdc9;
  }
  cursor: pointer;
}
.secondary {
  color: #5bc5d0;
  box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
  border: 1px solid #5bc5d0;
  width: 100%;
  display: block;
  background-color: #ffffff;
  padding: 8px;
  cursor: pointer;
  outline: none;
}
.green {
  background-color: #5bd064;
  color: white;
  width: 100%;
  height: 48px;
}
.disabled {
  @extend .secondary;
  color: #cfd8dc;
  margin-top: 0;
  height: 48px;
  font-family: 'Metropolis';
  border: 1px solid #cfd8dc;
  border-color: #cfd8dc;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  cursor: not-allowed;
}
.payments {
  @extend .secondary;
  text-align: center;
  width: 250px;
  margin: 30px auto;
}
.loadMoreBtn {
  @extend .payments;
}
.payNow {
  @extend .primary;
  padding: 8px;
  margin: 10px;
  width: 120px;
  height: 32px;
  background-color: #5bd064;
  margin-left: 1px;
  border-radius: 6px;
  &:hover {
    background-color: #3dc747;
  }
}
.schedulePayment {
  @extend .payNow;
  margin: 0px 0px 0px 18px;
  width: 184px;
  height: 32px;
  background-color: #5bd064;
  &:hover {
    background-color: #3dc747;
  }
}
.makePaymentsCancelPayment {
  color: #e22b41;
  text-transform: capitalize;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
  font-size: 11px;
  font-weight: 500;
}
.successfulPaymentButton {
  @extend .green;
  width: 87%;
  text-transform: uppercase;
  &:hover {
    background-color: #4ccc56;
  }
  margin: 13px auto;
  font-weight: 300;
}
.makePaymentsCheckBalance {
  @extend .makePaymentsCancelPayment;
  color: #5bc5d0;
  background-color: transparent;
  height: 16px;
}
.profileSettingsUpdate {
  margin-top: 32px;
  border-radius: 6px;
  color: #ffffff;
  line-height: 16px;
  font-weight: 500;
  font-size: 13px;
  background-color: #5bd064;
  transition: all 0.7s;
  &[class*='hidden'] {
    display: none !important;
  }
  &[class*='disabled'] {
    background-color: #cfd8dc;
    display: unset;
    height: 40px;
    margin-top: 32px;
    margin-bottom: 0;
    color: white !important;
  }
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .profileSettingsUpdate {
    border-radius: 6px;
    color: #ffffff;
    line-height: 16px;
    font-weight: 500;
    border-radius: 0px;
    font-size: 13px;
    width: 432px;
    background-color: #5bd064;
    transition: all 0.7s;
    &[class*='hidden'] {
      display: none !important;
    }
    &[class*='disabled'] {
      background-color: #cfd8dc;
      display: unset;
      height: 40px;
      margin-bottom: 0;
    }
  }
}
.profileSettingsUpdateEmail {
  @extend .profileSettingsUpdate;
  width: 100%;
  height: 48px !important;
}
.newCreditCardDiscard {
  padding-top: 2px;
  font-size: 11px;
  float: right;
  text-transform: capitalize;
  font-family: 'Metropolis';
  font-weight: 600;
  line-height: 13px;
  color: #e22b41;
}
.userSection {
  cursor: pointer;
  color: #5bc5d0;
  font-size: 15px;
  padding-right: 10px;
  font-weight: 500;
  padding-left: 20px;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  &:hover {
    color: #455a64;
  }
  &:after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    left: 0.15em;
    position: relative;
    width: 0.35em;
    top: -3px;
    margin-left: 5px;
    margin-right: 5px;
    transform: rotate(135deg);
    background-color: transparent;
  }
}
.userButton{
  cursor: pointer;
  color: #5bc5d0;
  font-size: 15px;
  padding-right: 10px;
  font-weight: 500;
  font-family: 'Metropolis';
  text-transform: capitalize;
  background-color: transparent;
  transition: color 0.5s;
  &:hover {
    color: #455a64;
    color: #455a64;
  }
  &:after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    left: 0.15em;
    position: relative;
    width: 0.35em;
    top: -3px;
    margin-left: 5px;
    margin-right: 5px;
    transform: rotate(135deg);
    background-color: transparent;
  }
}
$breakpoint-tablet: 567px;
@media (max-width: $breakpoint-tablet) {
  .userButton {
    font-size: 12px;
  }
}
$breakpoint-tablet: 400px;
@media (max-height: $breakpoint-tablet) {
  .userSection {
    cursor: pointer;
    color: #5bc5d0;
    font-size: 10px;
    padding-right: 0px;
    font-weight: 400;
    padding-left: 20px;
    font-family: 'Metropolis';
    text-transform: capitalize;
    background-color: transparent;
    transition: color 0.5s;
    &:hover {
      color: #455a64;
      color: #455a64;
    }
    &:after {
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.35em;
      left: 0.15em;
      position: relative;
      width: 0.35em;
      top: -3px;
      margin-left: 5px;
      margin-right: 5px;
      transform: rotate(135deg);
      background-color: transparent;
    }
  }
}
$breakpoint-tablet: 360px;
@media (max-height: $breakpoint-tablet) {
  .userSection {
    cursor: pointer;
    color: #5bc5d0;
    font-size: 6px;
    padding-right: 0px;
    font-weight: 400;
    padding-left: 20px;
    font-family: 'Metropolis';
    text-transform: capitalize;
    background-color: transparent;
    transition: color 0.5s;
    &:hover {
      color: #455a64;
      color: #455a64;
    }
    &:after {
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.35em;
      left: 0.15em;
      position: relative;
      width: 0.35em;
      top: -3px;
      margin-left: 5px;
      margin-right: 5px;
      transform: rotate(135deg);
      background-color: transparent;
    }
  }
}
.languageDropdown {
  @extend .userSection;
  border: none;
  border-radius: 0px;
}
.creditCardDetails {
  height: 17px;
  width: 39px !important;
  color: #5bc5d0;
  font-family: 'Metropolis';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  align-self: center;
  margin-right: 10px;
}
.deleteCreditCard {
  @extend .newCreditCardDiscard;
  text-transform: capitalize;
  background-color: transparent;
  text-align: right;
  padding: 0 10px 0 0;
  padding-top: 0;
  margin-top: 3px;
  margin-bottom: 3px;
}
.careTeamActionDownload {
  padding: 0;
  margin-bottom: 8px;
  height: 20px;
  text-align: left;
  font-size: 14px;
  color: #b0bec5;
  font-weight: 400;
  text-transform: capitalize;
  &:hover {
    color: #5bc5d0;
  }
}
.surveySubmitButton {
  color: #60ce69;
  background-color: #fff;
  border: 1px solid #60ce69;
  font-weight: 400;
  font-size: 14px;
  width: 320px;
  margin: 10px auto;
  &[class*='disabled'] {
    background-color: gainsboro;
    color: #fff;
    border: 1px solid #ddd;
  }
}
.surveyNoThankYouButton {
  @extend .surveySubmitButton;
  color: #5bc5d0;
  border-color: #5bc5d0;
}
.modalButtonBase {
  display: flex;
  background-color: #5bc5d0;
  color: #fff;
  margin: 15px auto;
  width: 300px;
  justify-content: center;
  align-items: center;
}
.manageProfilePracticeExpendedButtonMerge {
  @extend .secondary;
  margin: 0 10px;
  width: 260px;
  grid-row: 1;
  font-weight: 500;
}
.manageDelegatesPracticeExpendedButtonMerge {
  @extend .manageProfilePracticeExpendedButtonMerge;
  width: 200px;
}
.manageProfilePracticeExpendedButtonDisconnect {
  @extend .secondary;
  width: 260px;
  color: #fff !important;
  background-color: #e22b41;
  border: 1px solid #e22b41;
  border-color: #e22b41;
  grid-row: 1;
  margin: 0 10px;
  font-weight: 500;
}
.manageDelegatesPracticeExpendedButtonDisconnect {
  @extend .manageProfilePracticeExpendedButtonDisconnect;
  width: 200px;
}
.mergeDelegateProfileConfirmationModalNo {
  @extend .manageProfilePracticeExpendedButtonDisconnect;
  width: 140px;
  height: 50px;
}
.mergeDelegateProfileConfirmationModalYes {
  @extend .mergeDelegateProfileConfirmationModalNo;
  background-color: #5bd064;
  border-color: #5bd064;
}
.telehealthBackToAppointmentsbtn {
  @extend .secondary;
  width: 316px;
  height: 49px;
  margin-right: 15px;
}
.telehealthStartVideoCall {
  @extend .startVideoAppt;
  width: 316px;
  height: 50px;
}
.quickAction {
  @extend .secondary;
  height: 48px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: none;
  width: fit-content;
}
.practiceSelectName {
  font-family: 'Metropolis';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #455a64;
  height: 36px;
  display: flex;
  padding-left: 12px;
  align-items: center;
  text-transform: none;
  flex: 1;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:after {
    border-style: solid;
    border-color: #455a64;
    border-width: 1.5px 1.5px 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    left: 0.15em;
    position: relative;
    width: 0.35em;
    top: -3px;
    margin-left: 5px;
    transform: rotate(135deg);
    background-color: transparent;
  }
}
$breakpoint-tablet: 400px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectName {
    font-family: 'Metropolis';
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #455a64;
    height: 30px;
    display: flex;
    padding-left: 12px;
    align-items: center;
    text-transform: none;
    flex: 1;
    justify-content: space-between;
    &:after {
      border-style: solid;
      border-color: #455a64;
      border-width: 1.5px 1.5px 0 0;
      content: '';
      display: inline-block;
      height: 0.35em;
      left: 0.15em;
      position: relative;
      width: 0.35em;
      top: -3px;
      margin-left: 5px;
      transform: rotate(135deg);
      background-color: transparent;
    }
  }
}
$breakpoint-tablet: 380px;
@media (max-height: $breakpoint-tablet) {
  .practiceSelectName {
    font-family: 'Metropolis';
    font-weight: 350;
    font-size: 6px;
    line-height: 10px;
  }
}

.practiceSelectBtn {
  font-family: 'Metropolis';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  color: #455a64;
  padding: 0px 12px 0px 12px;
  border-radius: 0px;
  border: 1px solid #e8e9e9;
  border-top: 0px;
  height: 36px;
  background-color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.practiceSelectBtn:first-child {
  border-top-right-radius: 18px;
}
.practiceSelectBtn:last-child {
  border-bottom-right-radius: 18px;
}
.visitSummaryBtn,
.scanQRBtn {
  height: fit-content;
  padding: 0;
  color: $main-color;
  text-decoration: none;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .practiceSelectName {
    font-size: 13px;
    padding-left: 7px;
  }
  .visitSummaryBtn,
  .scanQRBtn {
    height: fit-content;
    padding-bottom: 0px;
    padding-top: 5px;
    color: $main-color;
    text-decoration: none;
  }
}
.QRCodeSwitchBtniOS {
  @extend .checkInOverlayBtn;
  &[class*='active'] {
    color: #fff;
    box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
    background-color: #5bc5d0;
  }
}
.QRCodeSwitchBtnAndroid {
  @extend .checkInOverlayBtn;
  &[class*='active'] {
    color: #fff;
    box-shadow: 1px 1px 4px rgba(31, 155, 222, 0.4);
    background-color: #5bc5d0;
  }
}

div[id='useForRequestCancelAppointmentLabel'] {
  h6 {
    width: 100%;
    color: #5bc5d0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    &.missed {
      color: #90a4ae;
    }
  }
}

.nextAppointmentCheckIn,
.nextAppointmentReschedule,
.telehealthBtn,
.paynowBtn {
  padding: 10px 20px;
  height: 40px;
  border-radius: 4px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: white;
  box-sizing: border-box;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .nextAppointmentCheckIn,
  .nextAppointmentReschedule,
  .telehealthBtn,
  .paynowBtn {
    padding: 10px 20px;
    height: 40px;
    border-radius: 4px;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: white;
    box-sizing: border-box;
  }

  div[id='useForRequestCancelAppointmentLabel'] {
    h6 {
      width: 100%;
      color: #5bc5d0;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      &.missed {
        color: #90a4ae;
      }
    }
  }
}
.nextAppointmentCheckIn {
  background-color: #698ed0;
}
.nextAppointmentReschedule {
  background-color: #e17f4d;
  white-space: nowrap;
  height: unset;
  min-height: 40px;
}
div[id='useForRequestCancelAppointmentLabel'] {
  h6 {
    width: 100%;
    color: #5bc5d0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    &.missed {
      color: #90a4ae;
    }
  }
}
.telehealthBtn {
  background-color: #5b9bd5;
  max-width: fit-content;
  position: relative;
}
.telehealthBtn.disabled {
  background-color: #cfd8dc;
  outline: none;
}
.telehealthBtn.disabled:active {
  pointer-events: none;
}
.paynowBtn {
  background-color: #5bd064;
}
.visitHistoryBtn {
  border-radius: 25px;
  height: 24px;
  width: 60px;
  margin-left: auto;
  margin-right: 20px;
  background-color: #5bc5d0;
  padding-inline: 14px;
  color: white;
  box-shadow: 0px 0px 4px #5bc5d0;
}
.appointmentHistory,
.visitHistory,
.paymentHistory,
.visitSummaryHistory {
  border-radius: 25px;
  height: 36px;
  border: 1px solid $main-color;
  padding-inline: 14px;
  color: #424242;
  &[class*='active'] {
    color: white;
    background: $main-color;
    box-shadow: 0px 0px 4px $main-color;
  }
}
.noAppointmentBtn {
  color: white;
  background: #5bd064;
  padding: 10px 20px;
  height: 40px;
  border-radius: 4px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .noAppointmentBtn{
    font-size: 14px;
  }
}
.careTeamBtnMessage,
.careTeamBtnAddAppointment {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 7px 10px;
  height: 34px;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Metropolis';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
}
$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .careTeamBtnMessage {
    font-size: 10px;
  }
}
.careTeamBtnMessage{
  background: #5bd064;
  & img {
    content: url(../../../../src/assets/images/messagewhite.svg);
  }
}
.twofaResendBtn {
  background-color: transparent;
  color: $destructive;
  margin-top: 24px;
  padding: 0;
  margin: 0;
  border: none;
  width: fit-content;
  height: fit-content;
}
.twofaResendBtn.disabled {
  color: #777;
}
.loginOTPVerifyBtn,
.twofaVerifyBtn {
  @extend .primary;
  background-color: $highlight;
  &:hover {
    background-color: #3dc747;
  }
}
.loginOTPVerifyBtn.disabled,
.twofaVerifyBtn.disabled {
  background-color: transparent;
  color: #cfd8dc;
  outline: none;
  pointer-events: none;
  height: 40px;
}
.twofaEnable {
  padding-inline: 24px;
  background-color: $highlight;
  color: $white;
  font-size: 15px;
}
.twofaSkip {
  padding-inline: 24px;
  background-color: $lighter_destructive;
  color: $white;
  font-size: 15px;
}
.completeRegistrationBtn {
  @extend .primary;
  padding-inline: 24px;
}
.twofaEnableBtn {
  @extend .profileSettingsUpdateEmail;
}
.emptySectionPreRegister{
  border: none;
  text-transform: none;
  text-decoration: none;
  color: $main-color;
  font-family: 'Metropolis', sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.nextAppointmentshowTodaybutton{
  border: 0.5px solid #5bc5d0;
  border-radius: 5px;
  padding: 0px 19px 0px 19px;
  height: 30px;
  width: 110px;
  color: #424242;
  &[class*='active'] {
    color: white;
    background: $main-color;
    box-shadow: 5px 0px 0px 0px $main-color;
  }
}
.nextAppointmentshowUpcomingbutton {
  border: 0.5px solid #5bc5d0;
  border-radius: 5px;
  padding: 0px 19px 0px 19px;
  height: 30px;
  width: 110px;
  color: #424242;
  &[class*='active'] {
    color: white;
    background: $main-color;
    box-shadow: -5px 0px 0px 0px #5bc5d0;
  }
}

.navigatebutton{
  justify-content: flex-end;
  gap: 4px;
  margin-right: 22px;
}

[class*='commonStyle']{
  border: 0.5px solid #5bc5d0;
  background-color: #5bc5d0;
  border-radius: 5px;
  height: 30px;
  width: 50px;
}
[class*='Disabled'] {
  cursor: not-allowed;
  pointer-events:none;
  border: none;
  background-color:transparent;

}

$breakpoint-mobile: 567px;
@media (max-width: $breakpoint-mobile)
{
  .navSignOut{
    font-size: 10px;
    font-weight: 500;
    color: #5bc5d0;
  }
}
